@use "../utils" as *;

.c-menu-sites {
    --menu-sites-color-background: var(--color-background-4);
    --menu-sites-color-icon: var(--color-text-9);

    z-index: var(--z-index-menu-sites);
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    background-color: var(--menu-sites-color-background);

    @media (min-width: $breakpoint-bar-sites) {
        display: none;
    }

    &__button-container {
        display: flex;
        justify-content: flex-end;
        padding: var(--padding-10);
    }

    &__button-close {
        @include reset-button;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__icon {
        font-size: var(--font-size-16);
        color: var(--menu-sites-color-icon);
    }

    &__content {
        padding: 0 var(--padding-20) var(--padding-20) var(--padding-20);
    }

    &--open {
        display: initial;
    }
}