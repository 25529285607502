@use "../utils" as *;

.c-accordion-menu-small {
    $block-context: &;

    --accordion-menu-small-color-icon: var(--color-text-4);
    --accordion-menu-small-color-border: var(--color-border-1);
    --accordion-menu-small-color-link: var(--color-text-4);

    width: 100%;
    border-bottom: var(--border-width-1) solid var(--accordion-menu-small-color-border);

    &__button {
        @include reset-button;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: var(--gap-16);
        padding: var(--padding-16);
    }

    &__icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__icon {
        font-size: var(--font-size-14);
        color: var(--accordion-menu-small-color-icon);
    }

    &__content {
        height: 0;
        overflow: hidden;
    }

    &__menu-content {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: var(--gap-24);
        padding: var(--padding-20);
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: var(--gap-24);
        padding: var(--padding-20);
    }

    &__link {
        @include reset-link;
    }

    &__label {
        font-size: var(--font-size-16);
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-150);
        color: var(--accordion-menu-small-color-link);

        &:focus,
        &:hover {
            text-decoration: underline;
        }
    }

    &.c-accordion--open {
        #{$block-context}__-con-container {
            transform: rotate(180deg);
        }
    }
}