@use "../utils" as *;

.c-grid-logo {
    width: 100%;
    display: grid;
    gap: var(--gap-24);

    @media (min-width: $breakpoint-l) {
        grid-template-columns: repeat(2, 1fr);
    }
}