:root {
    // Colors
    --color-beige: #f1d8ae; // 1
    --color-beige-light: #f9efdb; // 2
    --color-beige-verylight: #fcf8f0; // 3
    --color-blue-dark: #12253f; // 4
    --color-blue: #194473; // 5
    --color-grey-dark: #505050; // 6
    --color-grey: #d9d9d9; // 7
    --color-red: #dc091b; // 8
    --color-white: #ffffff; // 9

    // Text colors
    --color-text-1: var(--color-beige);
    --color-text-2: var(--color-beige-light);
    --color-text-3: var(--color-beige-verylight);
    --color-text-4: var(--color-blue-dark);
    --color-text-5: var(--color-blue);
    --color-text-6: var(--color-grey-dark);
    --color-text-7: var(--color-grey);
    --color-text-8: var(--color-red);
    --color-text-9: var(--color-white);

    // Background colors
    --color-background-1: var(--color-beige);
    --color-background-2: var(--color-beige-light);
    --color-background-3: var(--color-beige-verylight);
    --color-background-4: var(--color-blue-dark);
    --color-background-5: var(--color-blue);
    --color-background-6: var(--color-grey-dark);
    --color-background-7: var(--color-grey);
    --color-background-8: var(--color-red);
    --color-background-9: var(--color-white);

    // Border colors
    --color-border-1: var(--color-beige);
    --color-border-2: var(--color-beige-light);
    --color-border-3: var(--color-beige-verylight);
    --color-border-4: var(--color-blue-dark);
    --color-border-5: var(--color-blue);
    --color-border-6: var(--color-grey-dark);
    --color-border-7: var(--color-grey);
    --color-border-8: var(--color-red);
    --color-border-9: var(--color-white);

    // Gradients
    --gradient-1: linear-gradient(180deg, transparent 0%, var(--color-background-4) 100%);

    // Box shadows
    --box-shadow-1: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);

    // Layout
    --margin-2: 2px;
    --margin-5: 5px;
    --margin-10: 10px;
    --margin-16: 16px;
    --margin-20: 20px;
    --margin-24: 24px;

    --padding-2: 2px;
    --padding-5: 5px;
    --padding-8: 8px;
    --padding-10: 10px;
    --padding-12: 12px;
    --padding-14: 14px;
    --padding-16: 16px;
    --padding-18: 18px;
    --padding-20: 20px;
    --padding-24: 24px;
    --padding-30: 30px;
    --padding-40: 40px;
    --padding-50: 50px;
    --padding-80: 80px;
    --padding-layout-small: var(--padding-20);
    --padding-layout-large: var(--padding-40);
    --padding-header-horizontal: var(--padding-10);

    --gap-2: 2px;
    --gap-6: 6px;
    --gap-8: 8px;
    --gap-10: 10px;
    --gap-12: 10px;
    --gap-14: 14px;
    --gap-16: 16px;
    --gap-20: 20px;
    --gap-24: 24px;
    --gap-30: 30px;
    --gap-40: 40px;
    --gap-60: 60px;
    --gap-main: var(--gap-40);

    --z-index-skip-links: 60;
    --z-index-modal: 50;
    --z-index-alert: 40;
    --z-index-menu-sites: 30;
    --z-index-menu-small: 20;
    --z-index-header: 10;
    --z-index-above: 1;
    --z-index-base: 0;
    --z-index-below: -1;

    --width-page-print: 1000px;
    --max-width-inner: 1920px;
    --max-width-main: 1200px;
    --max-width-sidebar: calc(var(--max-width-main) - var(--gap-main) - var(--max-width-content));
    --max-width-content: 940px;
    --height-header-small: 75px;
    --height-header-large: 100px;
    --max-height-media: 630px;

    // Borders
    --border-radius-5: 5px;
    --border-radius-10: 10px;
    --border-radius-30: 30px;
    --border-radius-round: 50%;

    --border-width-1: 1px;
    --border-width-2: 2px;
    --border-width-4: 4px;

    // Fonts
    --font-family-text: "Inter", sans-serif;
    --font-family-title: "Inter", sans-serif;

    --font-size-reference: 16; // Used to convert px values to rem

    --font-size-10: calc((10 / var(--font-size-reference)) * 1rem);
    --font-size-12: calc((12 / var(--font-size-reference)) * 1rem);
    --font-size-14: calc((14 / var(--font-size-reference)) * 1rem);
    --font-size-16: calc((16 / var(--font-size-reference)) * 1rem);
    --font-size-18: calc((18 / var(--font-size-reference)) * 1rem);
    --font-size-20: calc((20 / var(--font-size-reference)) * 1rem);
    --font-size-22: calc((22 / var(--font-size-reference)) * 1rem);
    --font-size-24: calc((24 / var(--font-size-reference)) * 1rem);
    --font-size-26: calc((26 / var(--font-size-reference)) * 1rem);
    --font-size-32: calc((32 / var(--font-size-reference)) * 1rem);
    --font-size-36: calc((36 / var(--font-size-reference)) * 1rem);
    --font-size-50: calc((50 / var(--font-size-reference)) * 1rem);
    --font-size-60: calc((60 / var(--font-size-reference)) * 1rem);

    --font-size-default: var(--font-size-16);
    --font-size-smaller: smaller;

    --font-weight-light: 300;
    --font-weight-regular: 400;
    --font-weight-semi-bold: 600;
    --font-weight-bold: 700;
    --font-weight-black: 900;

    --font-style-italic: italic;

    --font-variant-small-caps: all-small-caps;

    --line-height-100: 100%;
    --line-height-120: 120%;
    --line-height-130: 130%;
    --line-height-150: 150%;

    --line-height-default: var(--line-height-150);

    // Aspect ratios
    --aspect-ratio-4-1: 4/1;
    --aspect-ratio-16-9: 16/9;
    --aspect-ratio-3-2: 3/2;
    --aspect-ratio-1: 1;
    --aspect-ratio-2-3: 2/3;

    // Opacities
    --opacity-0: 0;
    --opacity-1: 1;

    // Transitions
    --transition-duration-0-25: 0.25s;
    --transition-duration-0-5: 0.5s;

    --transition-function-ease: ease;
}