// More explicit box shadow
@mixin box-shadow($offset-x: 0, $offset-y: 6px, $blur-radius: 6px, $spread-radius: 0, $color: rgba(0, 0, 0, 0.16)) {
    box-shadow: $offset-x $offset-y $blur-radius $spread-radius $color;
}

// Break words properly
@mixin break-word() {
    hyphens: auto;
    word-break: break-word;
    word-wrap: break-word;
}

// General reset for input elements
@mixin reset-input {
    display: inline-block;
    padding: 0;
    margin: 0;
    vertical-align: middle;
    background: none;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    appearance: none;
}

// General reset for link elements
@mixin reset-link {
    color: inherit;
    text-decoration: inherit;
    cursor: pointer;
}

// General reset for button elements
@mixin reset-button {
    padding: 0;
    font: inherit;
    line-height: inherit;
    color: inherit;
    text-transform: inherit;
    cursor: pointer;
    background-color: transparent;
    border: 0;
}

// Hide an element while keeping it accessible
@mixin hidden {
    position: absolute;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
}

// Opposite of hidden
@mixin shown {
    position: inherit;
    width: initial;
    height: initial;
    margin: initial;
    padding: initial;
    border: initial;
    opacity: inherit;
    overflow: initial;
    pointer-events: all;
}

// Rich text Editor
@mixin rte {
    --rte-block-margin-bottom: var(--margin-16);
    --rte-list-vertical-margin: var(--margin-10);

    --rte-color-text-table: var(--color-text-4);
    --rte-color-background-table-head: var(--color-background-1);
    --rte-color-background-table-even: var(--color-background-2);
    --rte-color-background-table-odd: var(--color-background-3);
    --rte-color-border-table: var(--color-border-6);

    // Block elements
    p,
    ol,
    ul {
        &:not(:last-child) {
            margin-bottom: var(--rte-block-margin-bottom);
        }
    }

    // Modified text
    em,
    i {
        font-style: var(--font-style-italic);
    }

    strong,
    b {
        font-weight: var(--font-weight-bold);
    }

    sub {
        vertical-align: sub;
        font-size: var(--font-size-smaller);
        line-height: var(--line-height-100);
    }

    sup {
        vertical-align: super;
        font-size: var(--font-size-smaller);
        line-height: var(--line-height-100);
    }

    // Lists
    ol,
    ul {
        padding-left: 24px;
        margin-left: 10px;

        li {
            margin-bottom: var(--rte-list-vertical-margin);
        }

        // Nested lists
        ol,
        ul {
            margin-top: var(--rte-list-vertical-margin);
            margin-left: 0;
        }
    }

    ol,
    ol ol,
    ol ol ol,
    ol ol ol ol,
    ol ol ol ol ol,
    ol ol ol ol ol ol {
        list-style-type: decimal;
    }

    ul,
    ul ul,
    ul ul ul,
    ul ul ul ul,
    ul ul ul ul ul,
    ul ul ul ul ul ul {
        list-style-type: disc;
    }

    // Links
    a {
        @include reset-link;
        text-decoration: underline;
    }

    // Table
    figure {
        display: flex;
        flex-direction: column-reverse;
        gap: var(--gap-10);
        overflow-x: auto;
    }

    figcaption {
        font-size: var(--font-size-14);
        font-weight: var(--font-weight-semi-bold);
        line-height: var(--line-height-150);
        color: var(--rte-color-text-table);
    }

    table {
        color: var(--rte-color-text-table);
    }

    thead {
        tr {
            background-color: var(--rte-color-background-table-head);
        }
    }

    tbody {
        tr:nth-child(even) {
            background-color: var(--rte-color-background-table-even);
        }

        tr:nth-child(odd) {
            background-color: var(--rte-color-background-table-odd);
        }
    }

    th,
    td {
        min-width: 200px;
        padding: var(--padding-16);
        text-align: left;
        border: 0;

        &:not(:first-child) {
            border-left: var(--border-width-1) solid var(--rte-color-border-table);
        }
    }
}