@use "../utils" as *;

.c-gallery {
    $block-context: &;

    --height-row: 220px;

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--gap-24);

    @media (min-width: $breakpoint-l) {
        &--2 {
            #{$block-context}__list {
                grid-template-rows: var(--height-row);
                grid-template-columns: repeat(2, 1fr);
            }
        }

        &--3 {
            #{$block-context}__list {
                grid-template-rows: repeat(2, var(--height-row));
                grid-template-columns: repeat(2, 1fr);
            }

            #{$block-context}__item:last-child {
                grid-column-start: span 2;
            }
        }

        &--4 {
            #{$block-context}__list {
                grid-template-rows: repeat(2, var(--height-row));
                grid-template-columns: repeat(2, 1fr);
            }
        }

        &--5 {
            #{$block-context}__list {
                grid-template-rows: repeat(2, var(--height-row));
                grid-template-columns: repeat(6, 1fr);
            }

            #{$block-context}__item:nth-child(1),
            #{$block-context}__item:nth-child(2) {
                grid-column-start: span 3;
            }

            #{$block-context}__item:nth-child(3),
            #{$block-context}__item:nth-child(4),
            #{$block-context}__item:nth-child(5) {
                grid-column-start: span 2;
            }
        }
    }

    &__list {
        width: 100%;
        display: grid;
        gap: var(--gap-24);
    }

    &__button {
        @include reset-button;
        display: flex;
        width: 100%;
        height: 100%;
    }
}