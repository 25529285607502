@use "../utils" as *;

.c-socials {
    $block-context: &;

    --socials-color-text-dark: var(--color-text-4);
    --socials-color-text-light: var(--color-text-9);

    display: flex;
    flex-direction: column;
    gap: var(--gap-16);

    @media (min-width: $breakpoint-l) {
        flex-direction: row;
        align-items: center;
    }

    &__label {
        font-size: var(--font-size-14);
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-150);
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: var(--gap-16);
    }

    &__link {
        @include reset-link;
    }

    &__icon {
        font-size: var(--font-size-24);
    }

    &--appearance-dark {
        #{$block-context}__label,
        #{$block-context}__icon {
            color: var(--socials-color-text-dark);
        }
    }

    &--appearance-light {
        #{$block-context}__label,
        #{$block-context}__icon {
            color: var(--socials-color-text-light);
        }
    }
}