@use "../utils" as *;

.c-resource {
    $block-context: &;

    --resource-color-icon-dark: var(--color-text-4);
    --resource-color-icon-light: var(--color-text-9);
    --resource-color-link-dark: var(--color-text-4);
    --resource-color-link-light: var(--color-text-9);
    --resource-color-text-dark: var(--color-text-4);
    --resource-color-text-light: var(--color-text-9);
    --resource-color-date-dark: var(--color-text-6);
    --resource-color-date-light: var(--color-text-9);

    display: flex;
    gap: var(--gap-8);

    &__icon {
        font-size: var(--font-size-14);
        line-height: var(--line-height-150);
    }

    &__inner {
        display: flex;
        flex-direction: column;
        gap: var(--gap-2)
    }

    &__link {
        @include reset-link;
        font-size: var(--font-size-16);
        font-weight: var(--font-weight-semi-bold);
        line-height: var(--line-height-150);

        &:focus,
        &:hover {
            text-decoration: underline;
        }
    }

    &__text,
    &__date {
        font-size: var(--font-size-12);
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-150);
    }

    &--appearance-dark {
        #{$block-context}__icon {
            color: var(--resource-color-icon-dark);
        }

        #{$block-context}__link {
            color: var(--resource-color-link-dark);
        }

        #{$block-context}__text {
            color: var(--resource-color-text-dark);
        }

        #{$block-context}__date {
            color: var(--resource-color-date-dark);
        }
    }

    &--appearance-light {
        #{$block-context}__icon {
            color: var(--resource-color-icon-light);
        }

        #{$block-context}__link {
            color: var(--resource-color-link-light);
        }

        #{$block-context}__text {
            color: var(--resource-color-text-light);
        }

        #{$block-context}__date {
            color: var(--resource-color-date-light);
        }
    }
}