@use "../utils" as *;

.c-figure {
    $block-context: &;

    --figure-color-background-dark: var(--color-background-3);
    --figure-color-description-dark: var(--color-text-4);
    --figure-color-copyright-dark: var(--color-text-6);
    --figure-color-background-light: var(--color-background-3);
    --figure-color-description-light: var(--color-text-9);
    --figure-color-copyright-light: var(--color-text-9);

    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--gap-16);

    @media print {
        border: var(--border-width-1) solid var(--color-border-4) !important;
    }

    &__media-container {
        width: 100%;
        max-height: var(--max-height-media);
        display: flex;
    }

    &__figcaption {
        display: flex;
        flex-direction: column;
        gap: var(--gap-16);
    }

    &__figcaption-inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--gap-2);
    }

    &__description {
        font-size: var(--font-size-14);
        font-weight: var(--font-weight-semi-bold);
        line-height: var(--line-height-150);
        text-align: center;
    }

    &__copyright {
        font-size: var(--font-size-12);
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-150);
        text-align: center;
    }

    &--appearance-dark {
        #{$block-context}__media-container {
            background-color: var(--figure-color-background-dark);
        }

        #{$block-context}__description {
            color: var(--figure-color-description-dark);
        }

        #{$block-context}__copyright {
            color: var(--figure-color-copyright-dark);
        }
    }

    &--appearance-light {
        #{$block-context}__media-container {
            background-color: var(--figure-color-background-light);
        }

        #{$block-context}__description {
            color: var(--figure-color-description-light);
        }

        #{$block-context}__copyright {
            color: var(--figure-color-copyright-light);
        }
    }

    &--type-image {
        #{$block-context}__media-container {
            aspect-ratio: 3/2;
        }
    }
}