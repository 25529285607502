@use "../utils" as *;

.c-bar-sites {
    --bar-sites-color-background: var(--color-background-4);
    --bar-sites-color-text: var(--color-text-9);
    --bar-sites-color-icon: var(--color-text-9);

    display: flex;
    justify-content: center;
    padding: var(--padding-8) var(--padding-header-horizontal);
    background-color: var(--bar-sites-color-background);

    @media (min-width: $breakpoint-bar-sites) {

        &__button-open {
            display: none;
        }

        &__list {
            display: flex;
        }
    }

    @media print {
        display: none !important;
    }

    &__inner {
        max-width: var(--max-width-main);
        width: 100%;
        display: flex;
        align-items: center;
        gap: var(--gap-24);
    }

    &__label {
        font-size: var(--font-size-14);
        font-weight: var(--font-weight-semi-bold);
        line-height: var(--line-height-150);
        color: var(--bar-sites-color-text);
        text-transform: uppercase;
    }

    &__button-open {
        @include reset-button;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
    }

    &__icon {
        font-size: var(--font-size-16);
        color: var(--bar-sites-color-icon);
    }

    &__list {
        display: none;
        align-items: center;
        gap: var(--gap-24);
        margin-left: auto;
    }
}