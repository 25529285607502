// Unfortunately custom properties can't be used with media queries so we have to rely on SASS to create our breakpoint variables.
$breakpoint-m: 480px;
$breakpoint-l: 760px;
$breakpoint-xl: 1000px;
$breakpoint-xxl: 1300px;
$breakpoint-xxxl: 1700px;
$breakpoint-xxxxl: 1800px;

$breakpoint-html: $breakpoint-l;
$breakpoint-bar-sites: $breakpoint-xl;
$breakpoint-header: $breakpoint-xl;
$breakpoint-layout: $breakpoint-l;
$breakpoint-sidebar: $breakpoint-xxl;

// This is a trick to make sure that media queries are ordered correctly
@media (min-width: $breakpoint-m) {
    * {
        box-sizing: border-box;
    }
}

@media (min-width: $breakpoint-l) {
    * {
        box-sizing: border-box;
    }
}

@media (min-width: $breakpoint-xl) {
    * {
        box-sizing: border-box;
    }
}

@media (min-width: $breakpoint-xxl) {
    * {
        box-sizing: border-box;
    }
}

@media (min-width: $breakpoint-xxxl) {
    * {
        box-sizing: border-box;
    }
}

@media (min-width: $breakpoint-xxxxl) {
    * {
        box-sizing: border-box;
    }
}

@media print {
    * {
        box-sizing: border-box;
    }
}