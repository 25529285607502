@use "../utils" as *;

.c-footer {
    $block-context: &;

    --footer-color-border-dark: var(--color-border-1);
    --footer-color-border-light: var(--color-border-6);
    --footer-color-link-dark: var(--color-text-4);
    --footer-color-link-light: var(--color-text-9);
    --footer-color-text-dark: var(--color-text-4);
    --footer-color-text-light: var(--color-text-9);
    --footer-color-title-dark: var(--color-text-4);
    --footer-color-title-light: var(--color-text-9);

    position: relative;
    padding: var(--padding-20);

    @media (min-width: $breakpoint-l) {
        padding: var(--padding-50) var(--padding-40);

        &__menus {
            grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
            column-gap: var(--gap-24);
            row-gap: var(--gap-30);
        }

        &__logos {
            grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
        }

        &__baseline-list {
            flex-direction: row;
            flex-wrap: wrap;
        }
    }

    @media (min-width: $breakpoint-xl) {
        &__main {
            flex-grow: 1;
            flex-direction: row;
        }

        &__about {
            flex-grow: 1;
            flex-basis: 30%;
        }

        &__menus {
            flex-grow: 1;
            flex-basis: 70%;
        }

        &__logo {
            flex-direction: row;
            align-items: center;
        }
    }

    @media print {
        display: none !important;
    }

    &__container {
        display: flex;
        flex-direction: column;
        gap: var(--gap-24);
        max-width: var(--max-width-main);
        margin-right: auto;
        margin-left: auto;
    }

    &__main {
        display: flex;
        flex-direction: column;
        gap: var(--gap-40);
    }

    &__about {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--gap-16);
    }

    &__image-container {
        order: -1;
        display: flex;
    }

    &__image {
        max-height: 100px;
    }

    &__text {
        font-size: var(--font-size-14);
        line-height: var(--line-height-150);
    }

    &__menus {
        display: grid;
        gap: var(--gap-30);
    }

    &__menu {
        display: flex;
        flex-direction: column;
        gap: var(--gap-14);
    }

    &__menu-title {
        font-size: var(--font-size-16);
        line-height: var(--line-height-120);
        font-weight: var(--font-weight-bold);
    }

    &__menu-list {
        display: flex;
        flex-direction: column;
        gap: var(--gap-14);
    }

    &__menu-link {
        @include reset-link;
        font-size: var(--font-size-14);
        line-height: var(--line-height-100);

        &:focus,
        &:hover {
            text-decoration: underline;
        }
    }

    &__logos {
        display: grid;
        gap: var(--gap-24);
        padding-top: var(--padding-24);
        border-top: var(--border-width-1) solid;
    }

    &__logo {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--gap-16);
    }

    &__logo-image-container {
        flex-shrink: 0;
        display: flex;
    }

    &__logo-image {
        max-height: 64px;
    }

    &__logo-text {
        @include rte;
        font-size: var(--font-size-14);
        line-height: var(--line-height-150);
    }

    &__baseline {
        display: flex;
        flex-direction: column;
        gap: var(--gap-24);
        padding-top: var(--padding-24);
        border-top: var(--border-width-1) solid;
    }

    &__baseline-list {
        display: flex;
        flex-direction: column;
        gap: var(--gap-16);
    }

    &__baseline-link {
        @include reset-link;
        font-size: var(--font-size-14);
        line-height: var(--line-height-150);

        &:focus,
        &:hover {
            text-decoration: underline;
        }
    }

    &__baseline-button {
        @include reset-button;
        font-size: var(--font-size-14);
        line-height: var(--line-height-150);

        &:focus,
        &:hover {
            text-decoration: underline;
        }
    }

    &__copyright {
        font-size: var(--font-size-14);
        line-height: var(--line-height-150);
    }

    &--appearance-dark {
        #{$block-context}__text {
            color: var(--footer-color-text-dark);
        }

        #{$block-context}__menu-title {
            color: var(--footer-color-title-dark);
        }

        #{$block-context}__menu-link {
            color: var(--footer-color-link-dark);
        }

        #{$block-context}__logos {
            border-top-color: var(--footer-color-border-dark);
        }

        #{$block-context}__logo-text {
            color: var(--footer-color-link-dark);
        }

        #{$block-context}__baseline {
            border-top-color: var(--footer-color-border-dark);
        }

        #{$block-context}__baseline-link,
        #{$block-context}__baseline-button {
            color: var(--footer-color-link-dark);
        }

        #{$block-context}__copyright {
            color: var(--footer-color-text-dark);
        }
    }

    &--appearance-light {
        #{$block-context}__text {
            color: var(--footer-color-text-light);
        }

        #{$block-context}__menu-title {
            color: var(--footer-color-title-light);
        }

        #{$block-context}__menu-link {
            color: var(--footer-color-link-light);
        }

        #{$block-context}__logos {
            border-top-color: var(--footer-color-border-light);
        }

        #{$block-context}__logo-text {
            color: var(--footer-color-link-light);
        }

        #{$block-context}__baseline {
            border-top-color: var(--footer-color-border-light);
        }

        #{$block-context}__baseline-link,
        #{$block-context}__baseline-button {
            color: var(--footer-color-link-light);
        }

        #{$block-context}__copyright {
            color: var(--footer-color-text-light);
        }
    }
}