@use "../utils" as *;

.c-title {
    $block-context: &;

    --title-color-text-dark: var(--color-text-4);
    --title-color-text-light: var(--color-text-9);

    position: relative;

    @media (min-width: $breakpoint-l) {
        &__decoration {
            top: -5px;
        }

        &--size-1 {
            font-size: var(--font-size-50);
        }

        &--size-2 {
            font-size: var(--font-size-32);
        }

        &--size-3 {
            font-size: var(--font-size-26);
        }

        &--size-4 {
            font-size: var(--font-size-20);
        }
    }

    &__decoration {
        z-index: var(--z-index-below);
        position: absolute;
        top: -10px;
        left: 0px;
        width: 60px;
        height: 50px;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        overflow: hidden;
    }

    &__link {
        @include reset-link;

        &:hover,
        &:active {
            text-decoration: underline;
        }
    }

    sub {
        vertical-align: sub;
        font-size: var(--font-size-smaller);
        line-height: var(--line-height-100);
    }

    sup {
        vertical-align: super;
        font-size: var(--font-size-smaller);
        line-height: var(--line-height-100);
    }

    &--appearance-dark {
        color: var(--title-color-text-dark);
    }

    &--appearance-light {
        color: var(--title-color-text-light);
    }

    &--alignment-left {
        text-align: left;
    }

    &--alignment-center {
        width: 100%;
        text-align: center;
    }

    &--alignment-right {
        width: 100%;
        text-align: right;
    }

    &--decoration {
        padding-left: 40px;
    }

    &--size-1 {
        font-size: var(--font-size-36);
        font-weight: var(--font-weight-black);
        line-height: var(--line-height-120);
    }

    &--size-2 {
        font-size: var(--font-size-26);
        font-weight: var(--font-weight-black);
        line-height: var(--line-height-120);
    }

    &--size-3 {
        font-size: var(--font-size-22);
        font-weight: var(--font-weight-bold);
        line-height: var(--line-height-120);
    }

    &--size-4 {
        font-size: var(--font-size-18);
        font-weight: var(--font-weight-bold);
        line-height: var(--line-height-120);
    }

    &--size-5 {
        font-size: var(--font-size-16);
        font-weight: var(--font-weight-bold);
        line-height: var(--line-height-120);
    }

    &--size-6 {
        font-size: var(--font-size-16);
        font-style: italic;
        font-weight: var(--font-weight-bold);
        line-height: var(--line-height-120);
    }
}