@use "../utils" as *;

.c-grid-card-featured {
    width: 100%;
    display: grid;
    gap: var(--gap-24);

    @media (min-width: $breakpoint-l) {
        grid-template-columns: repeat(2, 1fr);

        &__item:first-child {
            grid-column: 1 / span 2;
        }
    }

    @media (min-width: $breakpoint-xl) {
        grid-template-columns: 2fr 1fr 1fr;

        &__item:first-child {
            grid-column: auto;
            grid-row: 1 / span 2;
        }
    }
}