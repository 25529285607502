@use "../utils" as *;

.c-search-facet-active {
    $block-context: &;

    --search-facet-active-color-label: var(--color-text-4);
    --search-facet-active-color-description: var(--color-text-4);
    --search-facet-active-color-link: var(--color-text-4);
    --search-facet-active-color-icon: var(--color-text-4);

    display: flex;
    flex-direction: column;
    gap: var(--gap-10);

    &__inner {
        display: flex;
        flex-direction: column;
        gap: var(--gap-6);

    }

    &__label {
        font-size: var(--font-size-16);
        font-weight: var(--font-weight-bold);
        line-height: var(--line-height-100);
        color: var(--search-facet-active-color-label);
    }

    &__description {
        font-size: var(--font-size-12);
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-150);
        color: var(--search-facet-active-color-description);
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: var(--gap-6);
    }

    &__link {
        @include reset-link;
        display: flex;
        align-items: center;
        gap: var(--gap-8);

        &:focus,
        &:hover {
            #{$block-context}__link-label {
                text-decoration: underline;
            }
        }
    }

    &__link-label {
        font-size: var(--font-size-12);
        font-weight: var(--font-weight-semi-bold);
        line-height: var(--line-height-150);
        color: var(--search-facet-active-color-link);
    }

    &__link-icon {
        font-size: var(--font-size-14);
        color: var(--search-facet-active-color-icon);
    }
}