@use "../utils" as *;

.c-search-auto-correct {
    --search-auto-correct-color-link: var(--color-text-4);

    font-size: var(--font-size-16);
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height-100);
    color: var(--search-facet-select-color-label);

    a {
        color: var(--search-auto-correct-color-link);
        text-decoration: underline;
    }
}