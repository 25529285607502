@use "../utils" as *;

.c-noscript {
    --noscript-color-border: var(--color-border-8);
    --noscript-color-icon: var(--color-text-8);
    --noscript-color-text: var(--color-text-8);

    display: flex;
    align-items: center;
    gap: var(--gap-8);
    padding: var(--padding-16);
    border: var(--border-width-1) solid var(--noscript-color-border);

    &__icon {
        font-size: var(--font-size-18);
        color: var(--noscript-color-icon);
    }

    &__text {
        font-size: var(--font-size-14);
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-150);
        color: var(--noscript-color-text);
    }
}