@use "../utils" as *;

.c-icon-text {
    $block-context: &;

    --icon-text-color-text-dark: var(--color-text-4);
    --icon-text-color-text-light: var(--color-text-9);

    display: flex;
    align-items: baseline;
    gap: var(--gap-6);

    &__icon {
        font-size: var(--font-size-14);
    }

    &__text {
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-150);
    }

    &--appearance-dark {
        #{$block-context}__icon,
        #{$block-context}__text {
            color: var(--icon-text-color-text-dark);
        }
    }

    &--appearance-light {
        #{$block-context}__icon,
        #{$block-context}__text {
            color: var(--icon-text-color-text-light);
        }
    }

    &--size-small {
        #{$block-context}__text {
            font-size: var(--font-size-12);
        }
    }

    &--size-medium {
        #{$block-context}__text {
            font-size: var(--font-size-14);
        }
    }

    &--size-large {
        #{$block-context}__text {
            font-size: var(--font-size-16);
        }
    }
}