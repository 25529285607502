@use "../utils" as *;

.c-button {
    $block-context: &;

    --button-color-background-dark: var(--color-background-5);
    --button-color-background-light: var(--color-background-2);
    --button-color-background-disabled: var(--color-background-7);
    --button-color-background-hover-dark: var(--color-background-4);
    --button-color-background-hover-light: var(--color-background-1);
    --button-color-background-hover-disabled: var(--color-background-7);
    --button-color-text-dark: var(--color-text-9);
    --button-color-text-disabled: var(--color-text-6);
    --button-color-text-light: var(--color-text-4);

    @include reset-button;
    @include reset-link;
    display: flex;
    align-items: baseline;
    gap: var(--gap-8);

    @media print {
        border: var(--border-width-1) solid var(--color-border-4) !important;
    }

    &__label {
        font-weight: var(--font-weight-semi-bold);
        line-height: var(--line-height-150);
    }

    &--appearance-dark {
        background-color: var(--button-color-background-dark);
        color: var(--button-color-text-dark);

        &:focus,
        &:hover {
            background-color: var(--button-color-background-hover-dark);
        }
    }

    &--appearance-light {
        background-color: var(--button-color-background-light);
        color: var(--button-color-text-light);

        &:focus,
        &:hover {
            background-color: var(--button-color-background-hover-light);
        }
    }

    &--appearance-disabled {
        background-color: var(--button-color-background-disabled);
        color: var(--button-color-text-disabled);
        cursor: not-allowed;

        &:focus,
        &:hover {
            background-color: var(--button-color-background-hover-disabled);
        }
    }

    &--bold {
        #{$block-context}__label {
            font-weight: var(--font-weight-black);
        }
    }

    &--rounded-left {
        border-top-left-radius: var(--border-radius-30);
        border-bottom-left-radius: var(--border-radius-30);
    }

    &--rounded-right {
        border-top-right-radius: var(--border-radius-30);
        border-bottom-right-radius: var(--border-radius-30);

    }

    &--size-small {
        padding: var(--padding-8) var(--padding-12);

        #{$block-context}__icon {
            font-size: var(--font-size-12);
        }

        #{$block-context}__label {
            font-size: var(--font-size-14);
        }
    }

    &--size-large {
        padding: var(--padding-12) var(--padding-18);

        #{$block-context}__icon {
            font-size: var(--font-size-14);
        }

        #{$block-context}__label {
            font-size: var(--font-size-16);
        }
    }
}