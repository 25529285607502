@use "../utils" as *;

.c-quote {
    --quote-color-text: var(--color-text-4);
    --quote-color-name: var(--color-text-4);
    --quote-color-description: var(--color-text-6);
    --quote-color-border: var(--color-border-1);

    display: flex;
    flex-direction: column;
    gap: var(--gap-16);
    width: 100%;
    padding-right: var(--padding-24);
    padding-left: var(--padding-24);
    border-left: var(--border-width-4) solid var(--quote-color-border);

    &__text {
        @include rte;
        font-size: var(--font-size-24);
        line-height: var(--line-height-default);
        font-weight: var(--font-weight-light);
        color: var(--quote-color-text);
    }

    &__author {
        display: flex;
        align-items: center;
        gap: var(--gap-16);
    }

    &__image-container {
        flex-shrink: 0;
        display: flex;
        width: 64px;
        height: 64px;
        overflow: hidden;
        border-radius: var(--border-radius-round);
    }

    &__about {
        display: flex;
        flex-direction: column;
        gap: var(--gap-6);
    }

    &__name {
        font-size: var(--font-size-16);
        line-height: var(--line-height-120);
        font-weight: var(--font-weight-bold);
        color: var(--quote-color-name);
    }

    &__description {
        font-size: var(--font-size-12);
        line-height: var(--line-height-120);
        font-weight: var(--font-weight-regular);
        color: var(--quote-color-description);
    }
}