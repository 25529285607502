@use "../utils" as *;

.c-note {
    --note-color-text-dark: var(--color-text-6);
    --note-color-text-light: var(--color-text-9);

    @include rte;
    font-size: var(--font-size-12);
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height-150);

    &--appearance-dark {
        color: var(--note-color-text-dark);
    }

    &--appearance-light {
        color: var(--note-color-text-light);
    }
}