@use "../utils" as *;

.c-wallpaper {
    $block-context: &;

    --wallpaper-color-background-light-1: #fcf8f0;
    --wallpaper-color-background-light-2: #f9efdb;
    --wallpaper-color-background-light-3: #ffd4de;
    --wallpaper-color-background-light-4: #f2d365;
    --wallpaper-color-background-light-5: #dfeeff;

    --wallpaper-color-background-dark-1: #12253f;
    --wallpaper-color-background-dark-2: #194473;
    --wallpaper-color-background-dark-3: #dc091b;
    --wallpaper-color-background-dark-4: #2b826c;
    --wallpaper-color-background-dark-5: #8b60ac;

    --wallpaper-color-pattern-light-1: #fbf3e6;
    --wallpaper-color-pattern-light-2: #f7e8cd;
    --wallpaper-color-pattern-light-3: #f5ccd5;
    --wallpaper-color-pattern-light-4: #ebcd62;
    --wallpaper-color-pattern-light-5: #d6eaff;

    --wallpaper-color-pattern-dark-1: #081629;
    --wallpaper-color-pattern-dark-2: #14365c;
    --wallpaper-color-pattern-dark-3: #ca0819;
    --wallpaper-color-pattern-dark-4: #287863;
    --wallpaper-color-pattern-dark-5: #80589e;

    --wallpaper-color-gradient-light-1: #ffffff;
    --wallpaper-color-gradient-light-2: #ffffff;
    --wallpaper-color-gradient-light-3: #ffd4de;
    --wallpaper-color-gradient-light-4: #f2d365;
    --wallpaper-color-gradient-light-5: #dfeeff;

    --wallpaper-color-gradient-dark-1: #12253f;
    --wallpaper-color-gradient-dark-2: #194473;
    --wallpaper-color-gradient-dark-3: #dc091b;
    --wallpaper-color-gradient-dark-4: #2b826c;
    --wallpaper-color-gradient-dark-5: #8b60ac;

    z-index: var(--z-index-below);
    position: absolute;
    inset: 0;

    @media print {
        display: none !important;
    }

    &::after {
        content: "";
        z-index: var(--z-index-above);
        position: absolute;
        inset: 0;
    }

    &__pattern {
        width: 100%;
        height: 100%;
    }

    &--color-1 {
        &#{$block-context}--appearance-light {
            background-color: var(--wallpaper-color-background-light-1);

            &#{$block-context}--gradient {
                &::after {
                    background: linear-gradient(transparent, var(--wallpaper-color-gradient-light-1));
                }
            }

            #{$block-context}__pattern {
                fill: var(--wallpaper-color-pattern-light-1);
            }
        }

        &#{$block-context}--appearance-dark {
            background-color: var(--wallpaper-color-background-dark-1);

            &#{$block-context}--gradient {
                &::after {
                    background: linear-gradient(transparent, var(--wallpaper-color-gradient-dark-1));
                }
            }

            #{$block-context}__pattern {
                fill: var(--wallpaper-color-pattern-dark-1);
            }
        }
    }

    &--color-2 {
        &#{$block-context}--appearance-light {
            background-color: var(--wallpaper-color-background-light-2);

            &#{$block-context}--gradient {
                &::after {
                    background: linear-gradient(transparent, var(--wallpaper-color-gradient-light-2));
                }
            }

            #{$block-context}__pattern {
                fill: var(--wallpaper-color-pattern-light-2);
            }
        }

        &#{$block-context}--appearance-dark {
            background-color: var(--wallpaper-color-background-dark-2);

            &#{$block-context}--gradient {
                &::after {
                    background: linear-gradient(transparent, var(--wallpaper-color-gradient-dark-2));
                }
            }

            #{$block-context}__pattern {
                fill: var(--wallpaper-color-pattern-dark-2);
            }
        }
    }

    &--color-3 {
        &#{$block-context}--appearance-light {
            background-color: var(--wallpaper-color-background-light-3);

            &#{$block-context}--gradient {
                &::after {
                    background: linear-gradient(transparent, var(--wallpaper-color-gradient-light-3));
                }
            }

            #{$block-context}__pattern {
                fill: var(--wallpaper-color-pattern-light-3);
            }
        }

        &#{$block-context}--appearance-dark {
            background-color: var(--wallpaper-color-background-dark-3);

            &#{$block-context}--gradient {
                &::after {
                    background: linear-gradient(transparent, var(--wallpaper-color-gradient-dark-3));
                }
            }

            #{$block-context}__pattern {
                fill: var(--wallpaper-color-pattern-dark-3);
            }
        }
    }

    &--color-4 {
        &#{$block-context}--appearance-light {
            background-color: var(--wallpaper-color-background-light-4);

            &#{$block-context}--gradient {
                &::after {
                    background: linear-gradient(transparent, var(--wallpaper-color-gradient-light-4));
                }
            }

            #{$block-context}__pattern {
                fill: var(--wallpaper-color-pattern-light-4);
            }
        }

        &#{$block-context}--appearance-dark {
            background-color: var(--wallpaper-color-background-dark-4);

            &#{$block-context}--gradient {
                &::after {
                    background: linear-gradient(transparent, var(--wallpaper-color-gradient-dark-4));
                }
            }

            #{$block-context}__pattern {
                fill: var(--wallpaper-color-pattern-dark-4);
            }
        }
    }

    &--color-5 {
        &#{$block-context}--appearance-light {
            background-color: var(--wallpaper-color-background-light-5);

            &#{$block-context}--gradient {
                &::after {
                    background: linear-gradient(transparent, var(--wallpaper-color-gradient-light-5));
                }
            }

            #{$block-context}__pattern {
                fill: var(--wallpaper-color-pattern-light-5);
            }
        }

        &#{$block-context}--appearance-dark {
            background-color: var(--wallpaper-color-background-dark-5);

            &#{$block-context}--gradient {
                &::after {
                    background: linear-gradient(transparent, var(--wallpaper-color-gradient-dark-5));
                }
            }

            #{$block-context}__pattern {
                fill: var(--wallpaper-color-pattern-dark-5);
            }
        }
    }
}