@use "../utils" as *;

.c-separator {
    --separator-color-background-medium: var(--color-background-1);
    --separator-color-background-light: var(--color-background-9);

    width: 100%;
    height: 1px;
    margin: 0;
    border: 0;

    @media print {
        background-color: var(--color-background-4) !important;
    }

    &--appearance-medium {
        background-color: var(--separator-color-background-medium);
    }

    &--appearance-light {
        background-color: var(--separator-color-background-light);
    }
}