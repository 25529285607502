.orejime-Notice-actions {
    display: flex !important;
    flex-wrap: wrap !important;
    gap: var(--gap-10) !important;

    .orejime-Button {
        margin: 0 !important;
    }
}

.orejime-Button {
    cursor: pointer !important;
}