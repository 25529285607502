@use "../utils" as *;

.c-carousel-tile {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--gap-24);

    &__slider {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: var(--gap-24);
        overflow: hidden;
    }

    &__head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: var(--gap-16);
    }

    &__navigation {
        display: flex;
        align-items: center;
        gap: var(--gap-16);
    }

    &__slides {
        display: flex;
        width: fit-content; // This is necessary to prevent flex from changing the width of the element
    }

    &__link-container {
        display: flex;
        justify-content: center;
    }
}