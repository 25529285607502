@use "../utils/" as *;

html {
    scroll-padding-top: var(--height-header-small);

    @media (min-width: $breakpoint-header) {
        scroll-padding-top: var(--height-header-large);
    }

    @media print {
        width: var(--width-page-print);

        @page {
            size: a4 portrait;
        }

        * {
            color: var(--color-text-4) !important;
            background: none !important;
            border-color: var(--color-border-4) !important;
        }
    }
}

body {
    font-family: var(--font-family-text);
    font-size: var(--font-size-default);
    line-height: var(--line-height-default);
    color: var(--color-text-4);
    background-color: var(--color-background-9);
    overscroll-behavior: none;
}