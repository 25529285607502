@use "../utils" as *;

.c-header {
    $block-context: &;

    --header-color-label: var(--color-text-4);
    --header-color-label-hover: var(--color-text-4);
    --header-color-border: var(--color-border-5);
    --header-color-background: var(--color-background-9);
    --header-color-icon: var(--color-text-4);

    position: sticky;
    top: 0;
    z-index: var(--z-index-header);

    @media (min-width: $breakpoint-header) {
        &__header {
            height: var(--height-header-large);
        }

        &__inner {
            justify-content: space-between;
            gap: var(--gap-24);
        }

        &__image-container {
            width: 80px;
            height: 80px;
        }

        &__navigation {
            display: initial;
        }

        &__button-menu-small {
            display: none;
        }
    }

    @media print {
        position: relative;

        &__header {
            box-shadow: none !important;
        }

        &__navigation,
        &__link-search {
            display: none !important;
        }
    }

    &__header {
        height: var(--height-header-small);
        display: flex;
        justify-content: center;
        padding-right: var(--padding-header-horizontal);
        padding-left: var(--padding-header-horizontal);
        background-color: var(--header-color-background);
        border-bottom: var(--border-width-1) solid var(--color-border-1);
        box-shadow: var(--box-shadow-1);
    }

    &__inner {
        max-width: var(--max-width-main);
        width: 100%;
        display: flex;
        align-items: center;
        gap: var(--gap-6);
    }

    &__image-container {
        width: 50px;
        height: 50px;
    }

    &__navigation {
        height: 100%;
        display: none;
    }

    &__list {
        height: 100%;
        display: flex;
        align-items: center;
        gap: var(--gap-24);
    }

    &__item {
        height: 100%;
    }

    &__button-menu-large,
    &__link-menu-large {
        @include reset-button;
        @include reset-link;
        height: 100%;
        display: flex;
        align-items: center;
        gap: var(--gap-6);
        border-bottom: var(--border-width-4) solid transparent;
        color: var(--header-color-label);

        &:focus,
        &:hover {
            color: var(--header-color-label-hover);
            border-bottom-color: var(--header-color-border);
        }
    }

    &__button-menu-large {
        &--active {
            color: var(--header-color-label-hover);
            border-bottom-color: var(--header-color-border);

            #{$block-context}__icon-menu-large {
                transform: rotate(180deg);
            }
        }
    }

    &__label-menu-large {
        font-size: var(--font-size-16);
        font-weight: var(--font-weight-semi-bold);
        line-height: var(--line-height-150);
        text-align: left;
    }

    &__icon-menu-large {
        font-size: var(--font-size-16);
    }

    &__button-menu-small {
        @include reset-button;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
    }

    &__link-search {
        @include reset-link;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__icon {
        font-size: var(--font-size-20);
        color: var(--header-color-icon);
    }
}