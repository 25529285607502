@use "../utils" as *;

.c-link {
    $block-context: &;

    --link-color-text-dark: var(--color-text-4);
    --link-color-text-light: var(--color-text-9);

    @include reset-button;
    @include reset-link;
    display: flex;
    align-items: baseline;
    gap: var(--gap-8);

    &:focus,
    &:hover {
        #{$block-context}__label {
            text-decoration: underline;
        }
    }

    &__icon {
        font-size: var(--font-size-14);
    }

    &__label {
        font-size: var(--font-size-16);
        font-weight: var(--font-weight-semi-bold);
        line-height: var(--line-height-150);
        text-align: left;
    }

    &--appearance-dark {
        #{$block-context}__icon,
        #{$block-context}__label {
            color: var(--link-color-text-dark);
        }
    }

    &--appearance-light {
        #{$block-context}__icon,
        #{$block-context}__label {
            color: var(--link-color-text-light);
        }
    }
}