@use "../utils" as *;

.c-search-facet-date {
    --search-facet-date-color-legend: var(--color-text-4);
    --search-facet-date-color-label: var(--color-text-4);
    --search-facet-date-color-description: var(--color-text-4);
    --search-facet-date-color-value: var(--color-text-4);
    --search-facet-date-color-background: var(--color-background-9);
    --search-facet-date-color-border: var(--color-border-4);

    display: flex;
    flex-direction: column;
    gap: var(--gap-10);

    @media (min-width: $breakpoint-l) {
        &__container {
            flex-direction: row;
            align-items: flex-end;
        }
    }

    &__legend {
        margin-bottom: var(--margin-10);
        font-size: var(--font-size-16);
        font-weight: var(--font-weight-bold);
        line-height: var(--line-height-100);
        color: var(--search-facet-date-color-legend);
    }

    &__label {
        font-size: var(--font-size-14);
        font-weight: var(--font-weight-bold);
        line-height: var(--line-height-100);
        color: var(--search-facet-select-color-label);
    }

    &__description {
        font-size: var(--font-size-12);
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-150);
        color: var(--search-facet-select-color-description);
    }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--gap-10);
    }

    &__field-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: var(--gap-6);
    }

    &__input {
        @include reset-input;
        padding: var(--padding-16);
        color: var(--search-facet-date-color-value);
        background-color: var(--search-facet-date-color-background);
        border: var(--border-width-1) solid var(--search-facet-date-color-border);
    }
}