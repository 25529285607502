@use "../utils" as *;

.c-accordion {
    $block-context: &;

    --accordion-color-background-button: var(--color-background-2);
    --accordion-color-background-content: var(--color-background-3);
    --accordion-color-icon: var(--color-text-4);

    width: 100%;

    @media print {
        &__content {
            height: initial !important;
        }
    }

    &__button {
        @include reset-button;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: var(--gap-16);
        padding: var(--padding-16);
        background-color: var(--accordion-color-background-button);
    }

    &__icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__icon {
        font-size: var(--font-size-14);
        color: var(--accordion-color-icon);
    }

    &__content {
        height: 0;
        overflow: hidden;
    }

    &__content-inner {
        display: flex;
        flex-direction: column;
        gap: var(--gap-24);
        padding: var(--padding-24) var(--padding-16);
        background-color: var(--accordion-color-background-content);
    }

    &--open {
        #{$block-context}__icon-container {
            transform: rotate(180deg);
        }
    }
}