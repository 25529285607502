@use "../utils" as *;

.c-grid-link-featured {
    width: 100%;
    display: grid;
    gap: var(--gap-10);

    @media (min-width: $breakpoint-l) {
        grid-template-columns: repeat(3, 1fr);
        gap: var(--gap-16);

        &__item {
            display: flex;
        }
    }

    @media (min-width: $breakpoint-xl) {
        grid-template-columns: repeat(6, 1fr);
    }

}