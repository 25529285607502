@use "../utils" as *;

.c-table-of-content {
    $block-context: &;

    --table-of-content-color-background-button: var(--color-background-2);
    --table-of-content-color-background-content: var(--color-background-3);
    --table-of-content-color-label: var(--color-text-4);
    --table-of-content-color-icon: var(--color-text-4);
    --table-of-content-color-link: var(--color-text-4);

    width: 100%;

    &__button {
        @include reset-button;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: var(--gap-8);
        padding: var(--padding-12) var(--padding-18);
        background-color: var(--table-of-content-color-background-button);
    }

    &__label {
        font-size: var(--font-size-16);
        font-weight: var(--font-weight-regular);
        color: var(--table-of-content-color-label);
    }

    &__icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__icon {
        font-size: var(--font-size-10);
        color: var(--table-of-content-color-icon);
    }

    &__content {
        height: 0;
        overflow: hidden;
    }

    &__content-inner {
        padding: var(--padding-18);
        background-color: var(--table-of-content-color-background-content);
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: var(--gap-12);
    }

    &__link {
        @include reset-link;
        font-size: var(--font-size-14);
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-100);
        color: var(--table-of-content-color-label);

        &:focus,
        &:hover {
            text-decoration: underline;
        }
    }

    &.c-accordion--open {
        #{$block-context}__icon-container {
            transform: rotate(180deg);
        }
    }
}