@use "../utils" as *;

.c-menu-large {
    --menu-large-color-background: var(--color-background-3);
    --menu-large-color-link: var(--color-text-4);

    z-index: var(--z-index-below);
    position: absolute;
    top: var(--height-header-large);
    left: 0;
    width: 100%;
    display: none;
    justify-content: center;

    @media (min-width: $breakpoint-header) {
        &--open {
            display: flex;
        }
    }

    &__inner {
        max-width: var(--max-width-main);
        width: 100%;
        display: flex;
        align-items: flex-start;
        background-color: var(--menu-large-color-background);
        box-shadow: var(--box-shadow-1);
    }

    &__content {
        flex-grow: 1;
        z-index: var(--z-index-above);
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: var(--gap-24);
        padding: var(--padding-40);
    }

    &__list {
        min-width: 890px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: min-content;
        gap: var(--gap-24);
        padding: var(--padding-40) var(--padding-24);
    }

    &__link {
        @include reset-link;

        &:focus,
        &:hover {
            text-decoration: underline;
        }
    }

    &__label {
        font-size: var(--font-size-16);
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-150);
        color: var(--menu-large-color-link);
    }
}