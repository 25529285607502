@use "../utils" as *;

.c-data {
    --data-color-data: var(--color-text-5);
    --data-color-text: var(--color-text-4);

    display: flex;
    flex-direction: column;
    gap: var(--gap-16);
    width: 100%;

    &__inner {
        display: flex;
        flex-direction: column;
        gap: var(--gap-8);
    }

    &__data {
        font-size: var(--font-size-50);
        font-weight: var(--font-weight-black);
        line-height: var(--line-height-100);
        color: var(--data-color-data);
    }

    &__text {
        @include rte;
        font-size: var(--font-size-16);
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-150);
        color: var(--data-color-text);
    }
}