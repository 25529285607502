@use "../utils" as *;

.c-map {
    z-index: var(--z-index-base);
    position: relative;
    width: 100%;
    min-height: 320px;

    &--square {
        min-height: 0;
        width: 100%;
        aspect-ratio: 1;
    }
}