@use "../utils" as *;

.c-contact {
    --contact-color-background: var(--color-background-3);

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gap-12);
    padding: var(--padding-24);
    background-color: var(--contact-color-background);

    @media print {
        border: var(--border-width-1) solid var(--color-border-4) !important;
    }
}