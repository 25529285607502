@use "../utils" as *;

.c-list-card {
    display: flex;
    flex-direction: column;
    gap: var(--gap-24);
    width: 100%;

    &__item {
        display: flex;
        flex-direction: column;
        gap: var(--gap-24);
    }
}