@use "../utils" as *;

.c-page-header {
    $block-context: &;

    --page-header-color-text-dark: var(--color-text-4);
    --page-header-color-text-light: var(--color-text-9);
    --page-header-color-date-dark: var(--color-text-6);
    --page-header-color-date-light: var(--color-text-9);

    position: relative;
    display: flex;
    justify-content: center;
    padding-top: var(--padding-20);
    padding-right: var(--padding-layout-small);
    padding-left: var(--padding-layout-small);

    @media (min-width: $breakpoint-l) {
        padding-top: var(--padding-40);
        padding-right: var(--padding-layout-large);
        padding-left: var(--padding-layout-large);

        &--padding-bottom {
            padding-bottom: var(--padding-40);
        }
    }

    &__inner {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: var(--gap-16);
    }

    &__list-tag {
        order: -1;
    }

    &__image-container {
        max-height: var(--max-height-media);
    }

    &__text {
        @include rte;
        font-size: var(--font-size-20);
        font-weight: var(--font-weight-light);
        line-height: var(--line-height-150);
    }

    &__date {
        font-size: var(--font-size-14);
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-150);
    }

    &--appearance-dark {
        #{$block-context}__text {
            color: var(--page-header-color-text-dark);
        }

        #{$block-context}__date {
            color: var(--page-header-color-date-dark);
        }
    }

    &--appearance-light {
        #{$block-context}__text {
            color: var(--page-header-color-text-light);
        }

        #{$block-context}__date {
            color: var(--page-header-color-date-light);
        }
    }

    &--padding-bottom {
        padding-bottom: var(--padding-20);
    }

    &--size-large {
        #{$block-context}__inner {
            max-width: var(--max-width-main);
        }
    }

    &--size-small {
        #{$block-context}__inner {
            max-width: var(--max-width-content);
        }
    }
}