@use "../utils" as *;

.c-read-more {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--gap-24);

    @media (min-width: $breakpoint-xl) {
        flex-direction: row;

        &__title-container {
            flex-basis: 30%;
        }

        &__list {
            flex-grow: 1;
            grid-template-columns: 1fr 1fr;
        }
    }

    &__list {
        display: grid;
        gap: var(--gap-12);
    }

    &__item {
        display: flex;
    }
}