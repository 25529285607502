@use "../utils" as *;

.c-tag {
    --tag-color-text-dark: var(--color-text-8);
    --tag-color-text-light: var(--color-text-9);

    font-size: var(--font-size-12);
    line-height: var(--line-height-150);
    text-transform: uppercase;

    &--appearance-dark {
        color: var(--tag-color-text-dark);
    }

    &--appearance-light {
        color: var(--tag-color-text-light)
    }

    &--size-small {
        font-weight: var(--font-weight-regular);
    }

    &--size-large {
        font-weight: var(--font-weight-bold);
    }
}