@use "../utils" as *;

.c-link-featured {
    $block-context: &;

    --link-featured-color-text: var(--color-text-4);
    --link-featured-color-background: var(--color-background-2);
    --link-featured-color-text-featured: var(--color-text-9);
    --link-featured-color-background-featured: var(--color-background-8);

    @include reset-link;
    display: flex;
    align-items: center;
    gap: var(--gap-10);
    width: 100%;
    padding: var(--padding-10);
    background-color: var(--link-featured-color-background);

    @media (min-width: $breakpoint-l) {
        flex-direction: column;
        gap: var(--gap-8);
        padding: var(--padding-20);

        &__image {
            width: 32px;
        }

        &__label {
            text-align: center;
        }
    }

    @media print {
        border: var(--border-width-1) solid var(--color-border-4) !important;
    }

    &:focus,
    &:hover {
        #{$block-context}__label {
            text-decoration: underline;
        }
    }

    &__image {
        width: 24px;
    }

    &__label {
        font-size: var(--font-size-14);
        font-weight: var(--font-weight-semi-bold);
        line-height: var(--line-height-150);
        color: var(--link-featured-color-text);
    }

    &--featured {
        background-color: var(--link-featured-color-background-featured);

        #{$block-context}__icon,
        #{$block-context}__label {
            color: var(--link-featured-color-text-featured);
        }
    }
}