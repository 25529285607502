@use "../utils" as *;

.c-video {
    $block-context: &;

    --video-color-background-overlay: var(--color-background-7);

    position: relative;
    width: 100%;
    aspect-ratio: 16/9;

    &__video {
        width: 100%;
        height: 100%;
    }

    &__overlay {
        display: none;
        position: absolute;
        inset: 0;
        justify-content: center;
        align-items: center;
        background-color: var(--video-color-background-overlay);
    }

    // Show the overlay if the iframe has no src attribute
    iframe[src=""] {
        ~ #{$block-context}__overlay {
            display: flex;
        }
    }
}