@use "../utils" as *;

.c-frame {
    width: 100%;

    // Results & search
    &--solr_pi_results,
    &--solr_pi_search {
        display: flex;
        flex-direction: column;
        gap: var(--gap-30);
    }
}