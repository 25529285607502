@use "../utils" as *;

.c-card {
    $block-context: &;

    --card-color-background-badge: var(--color-background-8);
    --card-color-text-badge: var(--color-background-9);
    --card-color-text-primary-dark: var(--color-text-4);
    --card-color-text-primary-light: var(--color-text-9);
    --card-color-text-secondary-dark: var(--color-background-6);
    --card-color-text-secondary-light: var(--color-background-9);

    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--gap-16);

    @media (min-width: $breakpoint-l) {
        &--orientation-horizontal {
            #{$block-context}__thumbnail {
                max-width: 180px;
            }
        }
    }

    @media print {
        &__badge,
        &__badge-sound,
        &__badge-video {
            border: var(--border-width-1) solid var(--color-border-4) !important;
        }
    }

    &__thumbnail {
        order: -1;
        display: flex;
        flex-direction: column;
    }

    &__image-container {
        position: relative;
        display: flex;
    }

    &__badge {
        z-index: var(--z-index-above);
        position: absolute;
        top: 0;
        left: 0;
        padding: var(--padding-10);
        font-size: var(--font-size-10);
        font-weight: var(--font-weight-semi-bold);
        line-height: var(--line-height-130);
        color: var(--card-color-text-badge);
        background-color: var(--card-color-background-badge);
    }

    &__badge-sound,
    &__badge-video {
        z-index: var(--z-index-above);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: var(--font-size-14);
        color: var(--card-color-text-badge);
        background-color: var(--card-color-background-badge);
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--gap-8);
    }

    &__list-tag {
        order: -1;
    }

    &__number {
        font-size: var(--font-size-16);
        font-weight: var(--font-weight-bold);
        line-height: var(--line-height-150);
    }

    &__text {
        font-size: var(--font-size-16);
        font-weight: var(--font-weight-light);
        line-height: var(--line-height-150);
    }

    &__date-type {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: var(--gap-6);
    }

    &__date,
    &__type {
        font-size: var(--font-size-14);
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-150);
    }

    &__separator {
        display: block;
        width: 4px;
        height: 4px;
        border-radius: var(--border-radius-round);
    }

    &--appearance-dark {
        #{$block-context}__number,
        #{$block-context}__text {
            color: var(--card-color-text-primary-dark);
        }

        #{$block-context}__date,
        #{$block-context}__type {
            color: var(--card-color-text-secondary-dark);
        }

        #{$block-context}__separator {
            background-color: var(--card-color-text-secondary-dark);
        }
    }

    &--appearance-light {
        #{$block-context}__number,
        #{$block-context}__text {
            color: var(--card-color-text-primary-light);
        }

        #{$block-context}__date,
        #{$block-context}__type {
            color: var(--card-color-text-secondary-light);
        }

        #{$block-context}__separator {
            background-color: var(--card-color-text-secondary-light);
        }
    }

    &--orientation-horizontal {
        flex-direction: row;

        #{$block-context}__thumbnail {
            max-width: 96px;
        }

        #{$block-context}__content {
            flex-basis: 0;
            flex-grow: 1;
        }
    }
}