@use "../utils" as *;

.c-kiosk {
    $block-context: &;

    --kiosk-color-text-dark: var(--color-text-4);
    --kiosk-color-text-light: var(--color-text-9);

    display: grid;
    gap: var(--gap-24);

    @media (min-width: $breakpoint-l) {
        &__text {
            font-size: var(--font-size-24);
        }

        &__list {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media (min-width: $breakpoint-xxl) {
        grid-template-columns: 1fr 2fr;
    }

    &__head {
        display: flex;
        flex-direction: column;
        gap: var(--gap-24);
    }

    &__text {
        @include rte;
        font-size: var(--font-size-18);
        line-height: var(--line-height-150);
        font-weight: var(--font-weight-light);
    }

    &__list {
        display: grid;
        gap: var(--gap-24);
    }

    &--appearance-dark {
        #{$block-context}__text {
            color: var(--kiosk-color-text-dark);
        }
    }

    &--appearance-light {
        #{$block-context}__text {
            color: var(--kiosk-color-text-light);
        }
    }
}