@use "../utils" as *;

.c-feedback {
    --feedback-color-background: var(--color-background-3);

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gap-12);
    padding: var(--padding-24);
    background-color: var(--feedback-color-background);

    &__list {
        display: flex;
        align-items: center;
        gap: var(--gap-8);
    }

    &__text {
        @include rte;
        display: none;
    }

    @media print {
        border: var(--border-width-1) solid var(--color-border-4) !important;
    }
}