@use "../utils" as *;

.c-framed {
    --framed-color-border: var(--color-border-1);

    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--gap-24);
    padding: var(--padding-24);

    @media (min-width: $breakpoint-l) {
        flex-direction: row;
        align-items: flex-start;

        &__image-container {
            min-width: 160px;
            max-width: 160px;
        }

        &__map-container {
            min-width: 50%;
            max-width: 50%;
        }
    }

    @media print {
        border: var(--border-width-1) solid var(--color-border-4) !important;
    }

    &__image-container {
        min-width: 120px;
        max-width: 120px;
        display: flex;
        border-radius: var(--border-radius-round);
        overflow: hidden;
    }

    &__map-container {
        min-width: 100%;
        max-width: 100%;
    }

    &__content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--gap-24);
    }

    &--border {
        border: var(--border-width-1) solid var(--framed-color-border);
    }
}