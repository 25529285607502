@use "../utils" as *;

.c-cta {
    $block-context: &;

    --cta-color-text-dark: var(--color-text-4);
    --cta-color-text-light: var(--color-text-9);
    --cta-color-border: var(--color-border-1);

    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--gap-16);
    padding: var(--padding-40) var(--padding-24);

    @media print {
        border: var(--border-width-1) solid var(--color-border-4) !important;
    }

    &__text {
        @include rte;
        font-size: var(--font-size-16);
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-150);
    }

    &--appearance-dark {
        #{$block-context}__text {
            color: var(--cta-color-text-dark);
        }
    }

    &--appearance-light {
        #{$block-context}__text {
            color: var(--cta-color-text-light);
        }
    }

    &--border {
        border: var(--border-width-1) solid var(--cta-color-border);
    }
}