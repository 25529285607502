@use "../utils" as *;

.c-branding-info {
    $block-context: &;

    --branding-info-color-text-dark: var(--color-text-6);
    --branding-info-color-author-dark: var(--color-text-4);
    --branding-info-color-text-light: var(--color-text-9);
    --branding-info-color-author-light: var(--color-text-9);

    display: flex;
    align-items: center;
    gap: var(--gap-8);

    &__image-container {
        height: 22px;
    }

    // Override image width to fix bug on Safari
    .c-image {
        width: auto;
    }

    &__text {
        font-size: var(--font-size-12);
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-150);
    }

    &__author {
        font-weight: var(--font-weight-bold);
    }

    &--appearance-dark {
        #{$block-context}__text {
            color: var(--branding-info-color-text-dark);
        }

        #{$block-context}__author {
            color: var(--branding-info-color-author-dark);
        }
    }

    &--appearance-light {
        #{$block-context}__text {
            color: var(--branding-info-color-text-light);
        }

        #{$block-context}__author {
            color: var(--branding-info-color-author-light);
        }
    }
}