@use "../utils" as *;

.c-carousel-figure {
    --carousel-figure-color-text: var(--color-text-4);

    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--gap-24);

    &__slider {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: var(--gap-24);
        overflow: hidden;
    }

    &__head {
        display: flex;
        flex-direction: column;
        gap: var(--gap-16);
    }

    &__navigation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: var(--gap-16);
    }

    &__pagination {
        display: flex;
        align-items: baseline;
        gap: var(--gap-2);
        font-size: var(--font-size-16);
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-150);
        color: var(--carousel-figure-color-text);
    }

    &__slides {
        display: flex;
        width: fit-content; // This is necessary to prevent flex from changing the width of the element
    }
}