@use "../utils" as *;

.c-search-keyword {
    --search-keyword-color-label: var(--color-text-4);
    --search-keyword-color-description: var(--color-text-4);
    --search-keyword-color-value: var(--color-text-4);
    --search-keyword-color-icon: var(--color-text-4);
    --search-keyword-color-background: var(--color-background-9);
    --search-keyword-color-border: var(--color-border-4);
    --search-keyword-color-border-active: var(--color-border-5);
    --search-keyword-color-suggestion: var(--color-text-4);
    --search-keyword-color-suggestion-highlight: var(--color-text-8);

    --button-size: 40px;

    $block-context: &;

    display: flex;
    flex-direction: column;
    gap: var(--gap-6);

    @media (min-width: $breakpoint-l) {
        &__content {
            flex-direction: row;
            align-items: center;
        }
    }

    &__label {
        font-size: var(--font-size-16);
        font-weight: var(--font-weight-bold);
        line-height: var(--line-height-100);
        color: var(--search-keyword-color-label);
    }

    &__description {
        font-size: var(--font-size-12);
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-150);
        color: var(--search-keyword-color-description);
    }

    &__content {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--gap-10);
    }

    &__input-container {
        position: relative;
        width: 100%;
    }

    &__input {
        @include reset-input;
        position: relative;
        z-index: var(--z-index-above);
        width: 100%;
        padding: var(--padding-16);
        color: var(--search-keyword-color-value);
        background-color: var(--search-keyword-color-background);
        border: var(--border-width-1) solid var(--search-keyword-color-border);

        &::placeholder {
            color: var(--color-text-6);
            opacity: 1;
        }
    }

    &__listbox {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        max-height: 300px;
        display: none;
        flex-direction: column;
        gap: var(--gap-5);
        padding-top: var(--padding-16);
        padding-bottom: var(--padding-16);
        background-color: var(--search-keyword-color-background);
        border-right: var(--border-width-1) solid var(--search-keyword-color-border);
        border-bottom: var(--border-width-1) solid var(--search-keyword-color-border);
        border-left: var(--border-width-1) solid var(--search-keyword-color-border);
        overflow-y: auto;
        z-index: var(--z-index-above);

        &--open {
            display: flex;
        }
    }

    &__item {
        padding-right: var(--padding-16);
        padding-left: var(--padding-16);
        font-size: var(--font-size-14);
        color: var(--search-keyword-color-suggestion);
        border-top: var(--border-width-1) solid transparent;
        border-bottom: var(--border-width-1) solid transparent;
        cursor: pointer;

        &:hover,
        &--active {
            border-color: var(--search-keyword-color-border-active);
        }
    }

    &__highlight {
        font-weight: var(--font-weight-bold);
        color: var(--search-keyword-color-suggestion-highlight);
    }

    &__button {
        @include reset-button;
        position: absolute;
        z-index: var(--z-index-above);
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: var(--button-size);
        height: 100%;
    }

    &__icon {
        font-size: var(--font-size-16);
        color: var(--search-keyword-color-icon);
    }

    &--mode-homepage {
        align-items: center;

        #{$block-context}__content {
            max-width: 640px;
            justify-content: center;
        }

        #{$block-context}__input {
            border: var(--border-width-1) solid var(--search-keyword-color-border);
            border-radius: var(--border-radius-30);
            background-color: var(--search-keyword-color-background);
            padding-right: var(--button-size);
        }
    }
}