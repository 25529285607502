@use "../utils" as *;

.c-grid-tile {
    display: grid;
    gap: var(--gap-24);
    width: 100%;

    @media (min-width: $breakpoint-m) {
        grid-template-columns: repeat(2, 1fr);
    }
}