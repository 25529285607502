@use "../utils" as *;

.c-text {
    --text-color-text-dark: var(--color-text-4);
    --text-color-text-light: var(--color-text-9);

    @include rte;
    max-width: 100%;

    &--appearance-dark {
        color: var(--text-color-text-dark);
    }

    &--appearance-light {
        color: var(--text-color-text-light);
    }

    &--size-medium {
        font-size: var(--font-size-16);
    }

    &--size-large {
        font-size: var(--font-size-18);
        font-weight: var(--font-weight-light);
    }
}