@use "../utils" as *;

.c-banner {
    display: flex;
    justify-content: center;
    width: 100%;

    &__image-container {
        display: flex;
    }
}