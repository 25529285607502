@use "../utils" as *;

.c-button-facet {
    $block-context: &;

    --button-facet-color-label: var(--color-text-4);
    --button-facet-color-label-hover: var(--color-text-4);
    --button-facet-color-icon: var(--color-text-4);
    --button-facet-color-icon-hover: var(--color-text-4);
    --button-facet-color-border: var(--color-border-4);
    --button-facet-color-border-hover: var(--color-border-4);
    --button-facet-color-background: var(--color-background-3);
    --button-facet-color-background-hover: var(--color-background-2);

    @include reset-link;
    display: flex;
    align-items: center;
    gap: var(--gap-6);
    padding: var(--padding-10);
    background-color: var(--button-facet-color-background);
    border: var(--border-width-1) solid var(--button-facet-color-border);

    &:focus,
    &:hover {
        background-color: var(--button-facet-color-background-hover);
        border-color: var(--button-facet-color-border-hover);

        #{$block-context}__label {
            color: var(--button-facet-color-label-hover);
        }

        #{$block-context}__icon {
            color: var(--button-facet-color-icon-hover);
        }
    }

    &__label {
        font-size: var(--font-size-12);
        font-weight: var(--font-weight-semi-bold);
        line-height: var(--line-height-130);
        color: var(--button-facet-color-label);
    }

    &__icon {
        font-size: var(--font-size-16);
        color: var(--button-facet-color-icon);
    }
}