@use "../utils" as *;

.c-modal {
    --modal-color-background: var(--color-background-9);
    --modal-color-icon: var(--color-text-4);

    z-index: var(--z-index-modal);
    position: fixed;
    inset: 0;
    display: none;
    flex-direction: column;
    gap: var(--gap-10);
    overflow-y: auto;
    background-color: var(--modal-color-background);

    @media (min-width: $breakpoint-l) {
        &__content {
            padding: 0 var(--padding-40) var(--padding-40) var(--padding-40);
        }
    }

    &__button-container {
        display: flex;
        justify-content: flex-end;
        padding: var(--padding-10);
    }

    &__button-close {
        @include reset-button;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__icon {
        font-size: var(--font-size-26);
        color: var(--modal-color-icon);
    }

    &__content {
        padding: 0 var(--padding-20) var(--padding-20) var(--padding-20);
    }

    &--open {
        display: flex;
    }
}