@use "../utils" as *;

.c-page-header-columns {
    $block-context: &;

    --page-header-columns-color-text-dark: var(--color-text-4);
    --page-header-columns-color-text-light: var(--color-text-9);
    --page-header-columns-color-date-dark: var(--color-text-4);
    --page-header-columns-color-date-light: var(--color-text-9);
    --page-header-columns-color-copyright: var(--color-text-4);
    --page-header-columns-color-background-copyright: var(--color-text-9);

    position: relative;
    display: flex;
    justify-content: center;

    @media (min-width: $breakpoint-l) {
        &__content {
            padding: var(--padding-40);
        }
    }

    @media (min-width: $breakpoint-xxl) {
        &__inner {
            flex-direction: row;
        }

        &__content {
            flex-basis: 50%;
            padding: var(--padding-40) var(--padding-80) var(--padding-40) 0;
        }

        &__image-container {
            flex-basis: 50%;
        }
    }

    @media print {
        &__copyright {
            border: var(--border-width-1) solid var(--color-border-4) !important;
        }
    }

    &__inner {
        max-width: var(--max-width-main);
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    &__content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: var(--gap-16);
        padding: var(--padding-20);
    }

    &__list-tag {
        order: -1;
    }

    &__text {
        @include rte;
        font-size: var(--font-size-20);
        font-weight: var(--font-weight-light);
        line-height: var(--line-height-150);
    }

    &__date {
        font-size: var(--font-size-14);
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-150);
    }

    &__image-container {
        position: relative;
        display: flex;
    }

    &__copyright {
        z-index: var(--z-index-above);
        position: absolute;
        bottom: 20px;
        left: 20px;
        padding: var(--padding-8);
        font-size: var(--font-size-10);
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-150);
        color: var(--page-header-columns-color-copyright);
        background-color: var(--page-header-columns-color-background-copyright);
    }

    &--appearance-dark {
        #{$block-context}__text {
            color: var(--page-header-columns-color-text-dark);
        }

        #{$block-context}__date {
            color: var(--page-header-columns-color-date-dark);
        }
    }

    &--appearance-light {
        #{$block-context}__text {
            color: var(--page-header-columns-color-text-light);
        }

        #{$block-context}__date {
            color: var(--page-header-columns-color-date-light);
        }
    }
}