@use "../utils" as *;

.c-skip-links {
    --skip-links-color-background: var(--color-background-4);
    --skip-links-color-text: var(--color-text-9);

    z-index: var(--z-index-skip-links);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: var(--padding-20) var(--padding-header-horizontal);
    background-color: var(--skip-links-color-background);
    transform: translateY(-100%);

    @media (min-width: $breakpoint-header) {
        &__item {
            &--mobile {
                display: none;
            }

            &--desktop {
                display: initial;
            }
        }
    }

    &:focus-within {
        transform: translateY(0);
    }

    &__list {
        max-width: var(--max-width-main);
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        row-gap: var(--gap-16);
        column-gap: var(--gap-24);
    }

    &__item {
        &--desktop {
            display: none;
        }
    }

    &__link {
        @include reset-link;
        font-size: var(--font-size-12);
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-150);
        color: var(--skip-links-color-text);
        text-decoration: underline;
    }
}