@use "../utils" as *;

.c-menu-small {
    --menu-small-color: var(--color-text-4);
    --menu-small-color-background: var(--color-background-9);
    --menu-small-color-icon: var(--color-text-4);
    --menu-small-color-border: var(--color-border-1);

    z-index: var(--z-index-menu-small);
    position: fixed;
    inset: 0;
    display: none;
    overflow-y: auto;
    background-color: var(--menu-small-color-background);

    @media (min-width: $breakpoint-header) {
        display: none;
    }

    &__button-container {
        display: flex;
        justify-content: flex-end;
        padding: var(--padding-10);
    }

    &__button-close {
        @include reset-button;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__icon {
        font-size: var(--font-size-16);
        color: var(--menu-small-color-icon);
    }

    &__link {
        @include reset-link;
        width: 100%;
        display: block;
        padding: var(--padding-16);
        font-size: var(--font-size-16);
        font-weight: var(--font-weight-bold);
        line-height: var(--line-height-120);
        color: var(--menu-small-color);
        border-bottom: var(--border-width-1) solid var(--menu-small-color-border);

        &:focus,
        &:hover {
            text-decoration: underline;
        }
    }

    &--open {
        display: initial;
    }
}