@use "../utils" as *;

.c-tile {
    position: relative;
    background-color: var(--color-background-4);

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: var(--gap-12);
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0;
        padding: var(--padding-24);
        background-image: var(--gradient-1);
    }

    &__list-tag {
        order: -1;
    }

    &__date-type {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: var(--gap-6);
    }

    &__date,
    &__type {
        font-size: var(--font-size-14);
        line-height: var(--line-height-150);
        font-weight: var(--font-weight-regular);
        color: var(--color-text-9);
    }

    &__separator {
        display: block;
        width: 4px;
        height: 4px;
        border-radius: var(--border-radius-round);
        background-color: var(--color-text-9);
    }
}