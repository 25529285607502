@use "../utils" as *;

.c-image-links {
    $block-context: &;

    --image-links-color-text-dark: var(--color-text-4);
    --image-links-color-text-light: var(--color-text-9);

    display: flex;
    flex-direction: column;
    gap: var(--gap-24);

    @media (min-width: $breakpoint-l) {
        gap: var(--gap-40);

        &__text {
            font-size: var(--font-size-24);
        }

        &__columns {
            flex-direction: row;
            align-items: center;
        }

        &__image-container,
        &__buttons {
            flex-basis: 0;
            flex-grow: 1;
        }

        &__list {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media (min-width: $breakpoint-xxl) {
        gap: var(--gap-60);
    }

    &__head {
        display: flex;
        flex-direction: column;
        gap: var(--gap-24);
    }

    &__text {
        @include rte;
        font-size: var(--font-size-18);
        line-height: var(--line-height-150);
        font-weight: var(--font-weight-light);
        text-align: center;
    }

    &__columns {
        display: flex;
        flex-direction: column;
        gap: var(--gap-24);
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        gap: var(--gap-24);
    }

    &__list {
        display: grid;
        justify-items: start;
        gap: var(--gap-8);
    }

    &__link-container {
        display: flex;
        justify-content: center;
    }

    &--appearance-dark {
        #{$block-context}__text {
            color: var(--image-links-color-text-dark);
        }
    }

    &--appearance-light {
        #{$block-context}__text {
            color: var(--image-links-color-text-light);
        }
    }
}