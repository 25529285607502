@use "../utils" as *;

.c-meta {
    $block-context: &;

    --meta-color-text-dark: var(--color-text-6);
    --meta-color-text-light: var(--color-text-9);
    --meta-color-author-dark: var(--color-text-4);
    --meta-color-author-light: var(--color-text-9);

    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--gap-16);

    &__inner {
        display: flex;
        flex-direction: column;
        gap: var(--gap-10);
    }

    &__left {
        display: flex;
        align-items: center;
        gap: var(--gap-2);
    }

    &__date,
    &__type {
        font-size: var(--font-size-14);
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-150);
    }

    &__separator {
        width: 4px;
        height: 4px;
        border-radius: var(--border-radius-round);
    }

    &__author {
        font-size: var(--font-size-14);
        font-weight: var(--font-weight-bold);
        line-height: var(--line-height-150);
        white-space: nowrap;
    }

    &--appearance-dark {
        #{$block-context}__date,
        #{$block-context}__type {
            color: var(--meta-color-text-dark);
        }

        #{$block-context}__separator {
            background-color: var(--meta-color-text-dark);
        }

        #{$block-context}__author {
            color: var(--meta-color-author-dark);
        }
    }

    &--appearance-light {
        #{$block-context}__date,
        #{$block-context}__type {
            color: var(--meta-color-text-light);
        }

        #{$block-context}__separator {
            background-color: var(--meta-color-text-light);
        }

        #{$block-context}__author {
            color: var(--meta-color-author-light);
        }
    }

    @media (min-width: $breakpoint-l) {
        &__inner {
            flex-direction: row;
            flex-wrap: wrap;
        }

        &__author {
            margin-left: auto;
        }
    }
}