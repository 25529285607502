@use "../utils" as *;

.c-form {
    $block-context: &;

    --form-color-background-button: var(--color-background-5);
    --form-color-background-button-hover: var(--color-background-4);
    --form-color-label-button: var(--color-text-9);
    --form-color-label-button-hover: var(--color-text-9);

    --form-color-background-checkbox: var(--color-background-4);
    --form-color-background-field: var(--color-background-9);
    --form-color-background-radio: var(--color-background-4);
    --form-color-border: var(--color-border-4);
    --form-color-description: var(--color-text-4);
    --form-color-error: var(--color-text-8);
    --form-color-icon: var(--color-text-4);
    --form-color-label: var(--color-text-4);
    --form-color-legend: var(--color-text-4);
    --form-color-required: var(--color-text-8);
    --form-color-step: var(--color-text-4);
    --form-color-text: var(--color-text-4);
    --form-color-value: var(--color-text-4);

    // ----- Layout -----
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--gap-30);

    @media print {
        &__legend {
            background-color: var(--color-background-9) !important;
        }

        &__button {
            border: var(--border-width-1) solid var(--color-border-4) !important;
        }
    }

    &__step {
        font-size: var(--font-size-18);
        font-weight: var(--font-weight-bold);
        line-height: var(--line-height-150);
        color: var(--form-color-step);
    }

    &__group {
        display: flex;
        flex-direction: column;
        gap: var(--gap-6);
    }

    // ----- Fieldset -----
    &__fieldset {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-top: var(--margin-16);
    }

    &__legend {
        position: absolute;
        top: -12px;
        left: 0;
        padding-right: var(--padding-10);
        padding-left: var(--padding-10);
        font-size: var(--font-size-16);
        font-weight: var(--font-weight-bold);
        line-height: var(--line-height-150);
        color: var(--form-color-legend);
        background-color: var(--color-background-9);
    }

    &__fieldset-content {
        display: flex;
        flex-direction: column;
        gap: var(--gap-20);
        padding: var(--padding-20);
        border: var(--border-width-1) solid var(--form-color-border);
    }

    // ----- Field -----
    &__hidden {
        display: none;
    }


    &__label {
        font-size: var(--font-size-16);
        font-weight: var(--font-weight-bold);
        line-height: var(--line-height-100);
        color: var(--form-color-label);
    }

    &__required {
        font-size: var(--font-size-14);
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-100);
        color: var(--form-color-required);
    }

    &__error {
        font-size: var(--font-size-12);
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-120);
        color: var(--form-color-error);
    }

    &__description {
        font-size: var(--font-size-12);
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-150);
        color: var(--form-color-description);
    }

    &__date,
    &__email,
    &__number,
    &__select,
    &__telephone,
    &__text,
    &__textarea,
    &__url {
        @include reset-input;
        width: 100%;
        padding: var(--padding-16);
        font-size: var(--font-size-14);
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-110);
        color: var(--form--color-value);
        border: var(--border-width-1) solid var(--form-color-border);
        background-color: var(--form-color-background-field);
    }

    // ----- Select -----
    &__select-container {
        position: relative;
    }

    &__select {
        width: 100%;
        padding-right: var(--padding-40); // We need more padding on the right to avoid the icon
        cursor: pointer;
    }

    &__select-icon {
        position: absolute;
        top: 50%;
        right: 15px;
        pointer-events: none;
        transform: translateY(-50%);
        font-size: var(--font-size-16);
        color: var(--form-color-icon);
    }

    // ----- Checkbox -----
    &__checkbox-list {
        display: flex;
        flex-direction: column;
        gap: var(--gap-10);
    }

    &__checkbox-container {
        position: relative;
        display: flex;
        align-items: center;
        gap: var(--gap-10);
        cursor: pointer
    }

    &__checkbox {
        position: absolute;
        width: 0;
        height: 0;
        margin: 0;
        appearance: none;

        // Show checkbox mark
        &:checked ~ #{$block-context}__checkbox-fake::after {
            opacity: 1;
        }

        // Show focus
        &:focus ~ #{$block-context}__checkbox-fake {
            outline-style: auto;
        }
    }

    &__checkbox-fake {
        --size: 14px;
        width: var(--size);
        height: var(--size);
        min-width: var(--size);
        min-height: var(--size);
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--form-color-background-field);
        border: var(--border-width-1) solid var(--form-color-border);

        &::after {
            --size: 8px;
            content: "";
            position: absolute;
            width: var(--size);
            height: var(--size);
            opacity: 0;
            background-color: var(--form-color-background-checkbox);
        }
    }

    &__checkbox-label {
        font-size: var(--font-size-14);
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-110);
        color: var(--form-color-label);
    }

    // ----- Radio button-----
    &__radio-list {
        display: flex;
        flex-direction: column;
        gap: var(--gap-10);
    }

    &__radio-container {
        position: relative;
        display: flex;
        align-items: center;
        gap: var(--gap-10);
        cursor: pointer
    }

    &__radio {
        width: 0;
        height: 0;
        min-width: var(--size);
        min-height: var(--size);
        position: absolute;
        margin: 0;
        appearance: none;

        // Show radio mark
        &:checked ~ #{$block-context}__radio-fake::after {
            opacity: 1;
        }

        // Show focus
        &:focus ~ #{$block-context}__radio-fake {
            outline-style: auto;
        }
    }

    &__radio-fake {
        --size: 14px;
        width: var(--size);
        height: var(--size);
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--form-color-background-field);
        border: var(--border-width-1) solid var(--form-color-border);
        border-radius: var(--border-radius-round);

        &::after {
            content: "";
            --size: 8px;
            width: var(--size);
            height: var(--size);
            position: absolute;
            opacity: 0;
            background-color: var(--form-color-background-radio);
            border-radius: var(--border-radius-round);
        }
    }

    &__radio-label {
        font-size: var(--font-size-14);
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-110);
        color: var(--form-color-label);
    }

    // ----- Files -----
    &__file-upload,
    &__image-upload {
        width: 100%;
        padding: var(--padding-14);
        background-color: var(--form-color-background-field);
        border: var(--border-width-1) solid var(--form-color-border);
    }

    // Static
    &__static-container {
        display: flex;
        flex-direction: column;
        gap: var(--gap-10);
    }

    &__static-text {
        font-size: var(--font-size-16);
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-150);
        color: var(--form-color-text);
    }

    // ----- Button -----
    &__navigation {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: var(--gap-10);
    }

    &__button {
        @include reset-button;
        display: flex;
        align-items: center;
        padding: var(--padding-10) var(--padding-20);
        font-size: var(--font-size-16);
        font-weight: var(--font-weight-semi-bold);
        line-height: var(--line-height-150);
        color: var(--form-color-label-button);
        background-color: var(--form-color-background-button);

        &:hover,
        &:focus {
            color: var(--form-color-label-button-hover);
            background-color: var(--form-color-background-button-hover);
        }
    }
}