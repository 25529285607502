@use "../utils" as *;

.c-alert {
    --alert-color-icon: var(--color-text-4);
    --alert-color-background: var(--color-background-1);

    z-index: var(--z-index-alert);
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    justify-content: center;
    padding: var(--padding-16);
    background-color: var(--alert-color-background);

    @media print {
        display: none !important;
    }

    &__inner {
        width: 100%;
        max-width: var(--max-width-main);
        display: flex;
        flex-direction: column;
        gap: var(--gap-16);
    }

    &__head {
        display: flex;
        align-items: center;
        gap: var(--gap-10);
    }

    &__icon {
        font-size: var(--font-size-24);
        color: var(--alert-color-icon);
    }

    &__title {
        margin-right: auto;
    }

    &__button-close {
        @include reset-button;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__buton-close-icon {
        font-size: var(--font-size-16);
        color: var(--menu-sites-color-icon);
    }

    &--open {
        display: flex;
    }
}