@use "../utils" as *;

.c-search-facet-select {
    --search-facet-select-color-label: var(--color-text-4);
    --search-facet-select-color-description: var(--color-text-4);
    --search-facet-select-color-value: var(--color-text-4);
    --search-facet-select-color-icon: var(--color-text-4);
    --search-facet-select-color-background: var(--color-background-9);
    --search-facet-select-color-border: var(--color-border-4);

    display: flex;
    flex-direction: column;
    gap: var(--gap-6);

    &__label {
        font-size: var(--font-size-16);
        font-weight: var(--font-weight-bold);
        line-height: var(--line-height-100);
        color: var(--search-facet-select-color-label);
    }

    &__description {
        font-size: var(--font-size-12);
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-150);
        color: var(--search-facet-select-color-description);
    }

    &__select-container {
        position: relative;
    }

    &__select {
        @include reset-input;
        width: 100%;
        padding: var(--padding-16) var(--padding-40) var(--padding-16) var(--padding-16); // We need more padding on the right to avoid the icon
        font-size: var(--font-size-14);
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-110);
        color: var(--search-facet-select-color-value);
        background-color: var(--search-facet-select-color-background);
        border: var(--border-width-1) solid var(--search-facet-select-color-border);
        cursor: pointer;
    }

    &__icon {
        position: absolute;
        top: 50%;
        right: 15px;
        pointer-events: none;
        transform: translateY(-50%);
        font-size: var(--font-size-16);
        color: var( --search-facet-select-color-icon);
    }
}