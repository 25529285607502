@use "../utils" as *;

.c-logo {
    --logo-color-text: var(--color-text-4);

    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--gap-10);

    &__image-container {
        order: -1;
        display: flex;
        height: 130px;
    }

    &__image {
        width: initial; // Override contain mode
    }

    &__text {
        @include rte;
        font-size: var(--font-size-16);
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-150);
        color: var(--logo-color-text);
    }
}