@use "../utils" as *;

.c-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gap-20);

    @media (min-width: $breakpoint-l) {
        gap: var(--gap-30);
    }
}