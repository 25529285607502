@use "../utils" as *;

.c-hot-topics {
    --hot-topics-color-icon: var(--color-text-8);
    --hot-topics-color-text: var(--color-text-4);

    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: var(--gap-8);

    &__icon {
        font-size: var(--font-size-18);
        color: var(--hot-topics-color-icon);
    }

    &__label {
        font-size: var(--font-size-18);
        font-weight: var(--font-weight-bold);
        line-height: var(--line-height-150);
        color: var(--hot-topics-color-text);
        white-space: nowrap
    }

    &__list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: var(--gap-8);
    }

    &__item {
        display: flex;
        align-items: center;
        gap: var(--gap-8);
    }

    &__link {
        @include reset-link;
        font-size: var(--font-size-16);
        font-weight: var(--font-weight-semi-bold);
        line-height: var(--line-height-150);
        color: var(--hot-topics-color-text);

        &:focus,
        &:hover {
            text-decoration: underline;
        }
    }

    &__separator {
        width: 4px;
        height: 4px;
        background-color: var(--hot-topics-color-text);
        border-radius: var(--border-radius-round);
    }
}