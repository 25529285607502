@use "../utils" as *;

.c-pagination {
    $block-context: &;

    --pagination-color-link: var(--color-text-4);
    --pagination-color-link-hover: var(--color-text-9);
    --pagination-color-icon: var(--color-text-4);
    --pagination-color-icon-hover: var(--color-text-9);
    --pagination-color-active: var(--color-text-9);
    --pagination-color-ellipsis: var(--color-text-4);
    --pagination-color-background-link: var(--color-background-2);
    --pagination-color-background-link-hover: var(--color-background-5);
    --pagination-color-background-active: var(--color-background-5);
    --pagination-color-background-ellipsis: var(--color-background-2);

    width: 100%;
    display: flex;
    justify-content: center;

    @media print {
        &__link,
        &__active,
        &__ellipsis {
            border: var(--border-width-1) solid var(--color-border-4) !important;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: var(--gap-6);
    }

    &__link,
    &__active,
    &__ellipsis {
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__link {
        @include reset-link;
        font-size: var(--font-size-16);
        line-height: var(--line-height-100);
        color: var(--pagination-color-link);
        background-color: var(--pagination-color-background-link);

        &:focus,
        &:hover {
            color: var(--pagination-color-link-hover);
            background-color: var(--pagination-color-background-link-hover);

            #{$block-context}__icon {
                color: var(--pagination-color-icon-hover);
            }
        }

        &--previous {
            border-top-left-radius: var(--border-radius-30);
            border-bottom-left-radius: var(--border-radius-30);
        }

        &--next {
            border-top-right-radius: var(--border-radius-30);
            border-bottom-right-radius: var(--border-radius-30);
        }
    }

    &__icon {
        font-size: var(--font-size-16);
        color: var(--pagination-color-icon);
    }

    &__active {
        font-size: var(--font-size-16);
        font-weight: var(--font-weight-bold);
        line-height: var(--line-height-100);
        color: var(--pagination-color-active);
        background-color: var(--pagination-color-background-active);
    }

    &__ellipsis {
        font-size: var(--font-size-16);
        line-height: var(--line-height-100);
        color: var(--pagination-color-ellipsis);
        background-color: var(--pagination-color-background-ellipsis);
    }
}