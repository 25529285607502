@use "../utils" as *;

.c-wrapper-columns {
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
    padding: var(--padding-30) var(--padding-20);

    @media (min-width: $breakpoint-l) {
        padding: var(--padding-50) var(--padding-40);

        &__content {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media (min-width: $breakpoint-xxl) {
        padding-right: var(--padding-80);
        padding-left: var(--padding-80);
    }

    &__content {
        display: grid;
        gap: var(--gap-24);
        width: 100%;
        max-width: var(--max-width-main);
    }

    &__content-first,
    &__content-second {
        display: flex;
        flex-direction: column;
        gap: var(--gap-30);
    }
}