@use "../utils" as *;

.c-card-small {
    --card-small-image-size: 64px;
    --card-small-color-background: var(--color-background-3);
    --card-small-color-text: var(--color-background-4);
    --card-small-color-text-info: var(--color-background-6);

    width: 100%;
    display: flex;
    gap: var(--gap-16);
    padding: var(--padding-16);
    background-color: var(--card-small-color-background);

    @media print {
        border: var(--border-width-1) solid var(--color-border-4) !important;
    }

    &__image-container {
        display: flex;
        width: var(--card-small-image-size);
        min-width: var(--card-small-image-size);
        height: var(--card-small-image-size);
        min-height: var(--card-small-image-size);
    }

    &__image {
        border-radius: var(--border-radius-round);
        overflow: hidden;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: var(--gap-6);
    }

    &__text {
        @include rte;
        font-size: var(--font-size-16);
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-150);
        color: var(--card-small-color-text);
    }

    &__info {
        font-size: var(--font-size-14);
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-150);
        color: var(--card-small-color-text-info);
    }
}