@use "../utils" as *;

.c-wrapper {
    $block-context: &;

    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
    padding-right: var(--padding-20);
    padding-left: var(--padding-20);

    @media (min-width: $breakpoint-l) {
        padding-right: var(--padding-40);
        padding-left: var(--padding-40);

        &__content {
            padding-top: var(--padding-50);
            padding-bottom: var(--padding-50);
        }
    }

    @media (min-width: $breakpoint-xxl) {
        padding-right: var(--padding-80);
        padding-left: var(--padding-80);
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: var(--gap-30);
        width: 100%;
        max-width: var(--max-width-main);
        padding-top: var(--padding-30);
        padding-bottom: var(--padding-30);
    }

    &--border {
        #{$block-context}__content {
            border-top: var(--border-width-1) solid var(--color-border-1);
        }
    }
}