@use "../utils" as *;

.c-search-facet-button {
    --search-facet-button-color-label: var(--color-text-4);
    --search-facet-button-color-description: var(--color-text-4);

    display: flex;
    flex-direction: column;
    gap: var(--gap-6);

    &__label {
        font-size: var(--font-size-16);
        font-weight: var(--font-weight-bold);
        line-height: var(--line-height-100);
        color: var(--search-facet-button-color-label);
    }

    &__description {
        font-size: var(--font-size-12);
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-150);
        color: var(--search-facet-button-color-description);
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: var(--gap-6);
    }
}