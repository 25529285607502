@use "../utils" as *;

.c-message {
    $block-context: &;

    --message-color-icon-dark: var(--color-text-4);
    --message-color-icon-light: var(--color-text-9);
    --message-color-border: var(--color-border-1);

    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--gap-16);
    padding: var(--padding-40) var(--padding-24);

    @media print {
        border: var(--border-width-1) solid var(--color-border-4) !important;
    }

    &__head {
        display: flex;
        align-items: center;
        gap: var(--gap-10);
    }

    &__icon {
        font-size: var(--font-size-24);
    }

    &--appearance-dark {
        #{$block-context}__icon {
            color: var(--message-color-icon-dark);
        }
    }

    &--appearance-light {
        #{$block-context}__icon {
            color: var(--message-color-icon-light);
        }
    }

    &--border {
        border: var(--border-width-1) solid var(--message-color-border);
    }
}