@use "../utils" as *;

.c-image {
    $block-context: &;

    width: 100%;

    &--aspect-ratio-16-9 {
        aspect-ratio: var(--aspect-ratio-16-9);
    }

    &--aspect-ratio-3-2 {
        aspect-ratio: var(--aspect-ratio-3-2);
    }

    &--aspect-ratio-1 {
        aspect-ratio: var(--aspect-ratio-1);
    }

    &--aspect-ratio-2-3 {
        aspect-ratio: var(--aspect-ratio-2-3);
    }

    &--mode-responsive {
        height: auto;
    }

    &--mode-contain {
        height: 100%;
        object-fit: contain;
    }

    &--mode-cover {
        height: 100%;
        object-fit: cover;
    }
}