@use "../utils/" as *;

.c-layout {
    $block-context: &;

    --sidebar-spacing: 20px;

    position: relative;

    @media (min-width: $breakpoint-layout) {
        &__main {
            gap: var(--gap-40);
        }

        &__page {
            padding: var(--padding-layout-large);
        }

        &__content {
            gap: var(--gap-60);
        }

        &--homepage {
            #{$block-context}__content {
                gap: 0;
            }
        }
    }

    @media (min-width: $breakpoint-sidebar) {
        &__page-inner {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start; // This is necessary for the sidebar height
        }

        &__sidebar {
            flex-basis: 0;
            flex-grow: 1;
            position: sticky;
            top: var(--height-header-large);
            max-width: var(--max-width-sidebar);
            max-height: calc(100vh - var(--height-header-large));
            overflow-y: auto;
        }
    }

    @media print {
        &__sidebar {
            display: none !important;
        }
    }

    &__inner {
        max-width: var(--max-width-inner);
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    &__main {
        width: 100%;
    }

    &__page {
        position: relative;
        width: 100%;
        padding: var(--padding-layout-small);
    }

    &__page-inner {
        max-width: var(--max-width-main);
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: var(--gap-main);
        margin-left: auto;
        margin-right: auto;
    }

    &__sidebar {
        display: flex;
        flex-direction: column;
        padding: var(--padding-2); // Necessary for focus styles to be visible on table of content
    }

    &__content {
        flex-basis: 0;
        flex-grow: 1;
        max-width: var(--max-width-content);
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: var(--gap-40);
        margin-left: auto;
        margin-right: auto;
    }

    &--small {
        #{$block-context}__main-inner {
            max-width: var(--max-width-content);
        }
    }

    &--large {
        #{$block-context}__content {
            max-width: var(--max-width-main);
        }
    }

    &--homepage {
        #{$block-context}__inner {
            max-width: none;
        }

        #{$block-context}__page {
            padding: 0;
        }

        #{$block-context}__page-inner {
            max-width: none;
            margin: 0;
        }

        #{$block-context}__content {
            gap: 0;
            max-width: none;
        }
    }
}