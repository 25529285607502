@use "../utils" as *;

.c-page-header-home {
    $block-context: &;

    --page-header-home-min-height: 600px; // This value must be equal to image height to prevent image vertical cropping
    --page-header-home-font-family-title: var(--font-family-title);
    --page-header-home-color-subtitle-1-dark: var(--color-text-4);
    --page-header-home-color-subtitle-1-light: var(--color-text-9);
    --page-header-home-color-subtitle-2: var(--color-text-4);
    --page-header-home-color-text-dark: var(--color-text-4);
    --page-header-home-color-text-light: var(--color-text-9);
    --page-header-home-color-title-1-dark: var(--color-text-4);
    --page-header-home-color-title-1-light: var(--color-text-9);
    --page-header-home-color-title-2-dark: var(--color-text-8);
    --page-header-home-color-title-2-light: var(--color-text-9);

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--gap-16);

    @media (min-width: $breakpoint-l) {
        &__head-container {
            padding: var(--padding-50) var(--padding-40) var(--padding-40) var(--padding-40);
        }

        &__head {
            gap: var(--gap-40);
        }

        &__links-featured {
            padding-right: var(--padding-40);
            padding-left: var(--padding-40);
        }
    }

    @media (min-width: $breakpoint-xl) {
        &__head-container {
            min-height: var(--page-header-home-min-height);
            padding-top: var(--padding-80);
            padding-bottom: var(--padding-80);
        }

        &__head {
            position: relative;
            z-index: var(--z-index-above);
        }

        &__image-container {
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }

    @media (min-width: $breakpoint-xxl) {
        &__head-container,
        &__links-featured {
            padding-right: var(--padding-80);
            padding-left: var(--padding-80);
        }
    }

    &__main {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: var(--max-width-inner);
        position: relative;
    }

    &__head-container {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: var(--padding-30) var(--padding-20) var(--padding-20) var(--padding-20);
    }

    &__head {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: var(--gap-20);
        width: 100%;
        max-width: var(--max-width-main);
    }

    &__subtitle {
        font-size: var(--font-size-18);
        line-height: var(--line-height-120);
        font-weight: var(--font-weight-black);
        text-align: center;
    }

    &__search {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--gap-20);
    }

    &__label {
        font-size: var(--font-size-16);
        line-height: var(--line-height-150);
    }

    &__image-container {
        width: 100%;
    }

    &__links-featured {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--gap-16);
        width: 100%;
        padding-right: var(--padding-20);
        padding-left: var(--padding-20);
    }

    &__title-grid-link-featured {
        font-size: var(--font-size-18);
        line-height: var(--line-height-120);
        font-weight: var(--font-weight-black);
        color: var(--page-header-home-color-subtitle-2);
        text-align: center;
    }

    &__grid-link-featured-container {
        width: 100%;
        max-width: var(--max-width-main);
    }

    &--appearance-dark {
        #{$block-context}__subtitle {
            color: var(--page-header-home-color-subtitle-1-dark);
        }

        #{$block-context}__label {
            color: var(--page-header-home-color-text-dark);
        }
    }

    &--appearance-light {
        #{$block-context}__subtitle {
            color: var(--page-header-home-color-subtitle-1-light);
        }

        #{$block-context}__label {
            color: var(--page-header-home-color-text-light);
        }
    }
}