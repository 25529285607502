@use "../utils" as *;

.c-breadcrumb {
    --breadcrumb-color-background: var(--color-background-3);
    --breadcrumb-color-text: var(--color-text-4);

    display: flex;
    justify-content: center;
    padding: var(--padding-8) var(--padding-header-horizontal);
    background-color: var(--breadcrumb-color-background);

    @media print {
        &__separator {
            background-color: var(--color-background-4) !important;
        }
    }

    &__list {
        max-width: var(--max-width-main);
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: var(--gap-6);
    }

    &__item {
        display: flex;
        align-items: center;
        gap: var(--gap-6);
    }

    &__link {
        @include reset-link;
        font-size: var(--font-size-12);
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-150);
        color: var(--breadcrumb-color-text);
        text-decoration: underline;
    }

    &__separator {
        width: 4px;
        height: 4px;
        background-color: var(--breadcrumb-color-text);
        border-radius: var(--border-radius-round);
    }

    &__last {
        font-size: var(--font-size-12);
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-150);
        color: var(--breadcrumb-color-text);
    }
}