@use "../utils" as *;

.c-search-form {
    $block-context: &;

    --search-form-color-text: var(--color-text-4);
    --search-form-color-icon: var(--color-text-4);
    --search-form-color-background: var(--color-background-3);
    --search-form-color-border: var(--color-border-1);

    display: flex;
    flex-direction: column;
    gap: var(--gap-24);
    padding: var(--padding-16);
    background-color: var(--search-form-color-background);

    @media (min-width: $breakpoint-l) {
        padding: var(--padding-24);
    }

    &__accordion {
        border-top: var(--border-width-1) solid var(--search-form-color-border);
        border-bottom: var(--border-width-1) solid var(--search-form-color-border);
    }

    &__button {
        @include reset-button;
        width: 100%;
        padding-top: var(--padding-24);
        padding-bottom: var(--padding-24);
    }

    &__button-content-open,
    &__button-content-closed {
        display: flex;
        align-items: center;
        gap: var(--gap-8);
    }

    &__button-content-open {
        display: none;
    }

    &__icon {
        font-size: var(--font-size-14);
        color: var(--search-form-color-icon);
    }

    &__label {
        font-size: var(--font-size-16);
        font-weight: var(--font-weight-semi-bold);
        line-height: var(--line-height-150);
        color: var(--search-form-color-text);
    }

    &__content {
        height: 0;
        overflow: hidden;
    }

    &__content-inner {
        display: flex;
        flex-direction: column;
        gap: var(--gap-30);
        padding-bottom: var(--padding-24);
    }

    &__accordion.c-accordion--open {
        #{$block-context}__button-content-open {
            display: flex;
        }

        #{$block-context}__button-content-closed {
            display: none;
        }
    }
}